import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import swal from "sweetalert";

const ContactForm = ({ donner }) => {
  // const { fullName, phone, bloodGroup, fbID, distric } = donner;

  const form = useRef();
  const akah = `${donner?.fullName}, ${donner?.phone},${donner?.bloodGroup}, ${donner?.fbID}, ${donner?.distric}`;
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        `${process.env.REACT_APP_SERVICE_ID}`,
        `${process.env.REACT_APP_TEMPLATE_ID}`,
        form.current,
        `${process.env.REACT_APP_PUBLIC_KEY}`
      )
      .then(
        (result) => {
          if (result.status === 200) {
            swal(
              "Successfully send",
              "দয়া করে কিছুক্ষন অপেক্ষা করুন, আমরা আপনার সাথে যোগাযোগ করবো...।",
              "success"
            );
            form.current.reset();
          }
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <div className="md:ml-3 ml-0">
      <h3 className="text-4xl font-bold inline-block">Contact </h3>
      <span className="divider md:pr-12 my-2"></span>
      <form ref={form} onSubmit={sendEmail} className="max-w-md">
        <input
          type="text"
          placeholder="Your Name"
          className="input input-bordered w-full mt-3 "
          name="to_name"
        />
        <input
          type="mail"
          placeholder="Your Email"
          className="input input-bordered w-full mt-3"
          name="email"
        />
        <input
          type="text"
          placeholder="Subject"
          className="input input-bordered w-full mt-3 "
          name="subject"
        />
        <textarea
          className="textarea textarea-bordered block w-full mt-3"
          placeholder="Your message"
          rows={4}
          name="message"
        ></textarea>
        <input
          type="text"
          className="hidden "
          name="info"
          defaultValue={akah}
        />
        <button
          type="submit"
          className="btn main-color text-white border-0 mt-3"
        >
          Send Message
        </button>
      </form>
    </div>
  );
};

export default ContactForm;
