import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import { Autoplay, FreeMode, Pagination, Navigation } from "swiper";
import HomeHeading from "../../../Shared/HomeHeading";
import { useQuery } from "react-query";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";

import styles from "./OurCommite.module.css";
import Loading from "../../../Shared/Loading";

const OurCommite = () => {
  const {
    data: commites,
    isLoading,
    refetch,
  } = useQuery("our-commite-page", () =>
    fetch(`https://rokto-bondon-server.vercel.app/all-advice-notes`).then(
      (res) => res.json()
    )
  );

  if (isLoading) {
    return <Loading></Loading>;
  }
  return (
    <div className="md:px-16 px-2 my-0 md:my-24 md:mt-[70px] mt-[-60px]">
      <HomeHeading>মানবতার বাণী</HomeHeading>
      <>
        <Swiper
          slidesPerView={"auto"}
          spaceBetween={30}
          freeMode={true}
          pagination={{
            clickable: true,
          }}
          autoplay={{
            delay: 4500,
            disableOnInteraction: false,
          }}
          navigation={true}
          modules={[Autoplay, Pagination, Navigation]}
          id="commite-class "
        >
          {commites.map((commite) => {
            return (
              <SwiperSlide className={styles.widthslider}>
                <div className="shadow-lg p-3 border-2">
                  <div>
                    <p id="solaiman-font" className="text-justify">
                      {commite.advice}
                    </p>
                  </div>
                  <div className="flex items-start gap-3 mt-2 pt-3 border-t-2">
                    <div className="inline-block bg-white rounded-sm w-20">
                      <img
                        className="rounded-sm"
                        style={{ marginTop: "0" }}
                        src={commite.image}
                      />
                    </div>
                    <span>
                      <p className="text-3xl font-bold ">{commite.fullName}</p>
                      <p className="text-xl font-bold">{commite.position}</p>
                      <small className="text-sm" id="solaiman-font">
                        {commite.currentPosition}
                      </small>
                    </span>
                  </div>
                  <div></div>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </>
    </div>
  );
};

export default OurCommite;
