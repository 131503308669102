import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";

import Loading from "../Shared/Loading";
import DonnerProfile from "./DonnerProfile";

const Donner = () => {
  const { id } = useParams();
  const [donner, setDonner] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    const url = `https://rokto-bondon-server.vercel.app/donnerProfile/${id}`;
    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        setDonner(data);
        setLoading(false);
      });
    setLoading(false);
  }, [id]);

  /*  const { data: donner, isLoading } = useQuery("donnerProfile", () =>
    fetch(`https://rokto-bondon-server.vercel.app/donnerProfile/${id}`).then(
      (res) => res.json()
    )
  ); */

  if (loading) {
    return <Loading></Loading>;
  }

  return <DonnerProfile donner={donner}></DonnerProfile>;
};

export default Donner;
