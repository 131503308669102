import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import "swiper/css/scrollbar";

import "./Banner.css";

// import required modules
import { EffectCoverflow, Pagination, Autoplay, Scrollbar } from "swiper";
import { useQuery } from "react-query";
import Loading from "../../../Shared/Loading";

const Banner = () => {
  // const {name}=useQuery(bannerItems, ()=>)
  const { data: banner, isLoading } = useQuery("bannerSlider", () =>
    fetch("https://rokto-bondon-server.vercel.app/banner").then((res) =>
      res.json()
    )
  );

  if (isLoading) {
    return <Loading></Loading>;
  }

  return (
    <div className="my-slider">
      <Swiper
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        autoplay={true}
        slidesPerView={"auto"}
        navigation
        pagination={{ clickable: true }}
        coverflowEffect={{
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        }}
        modules={[EffectCoverflow, Pagination, Autoplay, Scrollbar]}
        className="mySwiper "
      >
        {banner.map((slider) => (
          <SwiperSlide>
            <img
              src={slider?.bannerImage ? slider?.bannerImage : slider?.extraUrl}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Banner;
