import React from "react";
import { useQuery } from "react-query";
import DasboardMenuHambarg from "../../Shared/DasboardMenuHambarg";
import Heading from "../../Shared/Heading";
import Loading from "../../Shared/Loading";
import { FaArrowCircleRight } from "react-icons/fa";

const Notice = () => {
  const { data: notice, isLoading } = useQuery(["notice"], () =>
    fetch("https://rokto-bondon-server.vercel.app/notice").then((res) =>
      res.json()
    )
  );
  if (isLoading) {
    return <Loading></Loading>;
  }

  return (
    <div>
      <Heading>
        <div className="flex justify-between items-center">
          <span>Notice Board</span>
          <DasboardMenuHambarg></DasboardMenuHambarg>
        </div>
      </Heading>
      <div>
        <marquee>
          <p className="font-bold text-2xl mt-3" id="solaiman-font">
            বিঃ দ্রঃ প্রতারক মুক্ত রাখার লক্ষ্যে আপনার পূর্ণাঙ্গ তথ্য দিয়ে
            প্রোফাইলটি সম্পূর্ণ করুন। ধন্যবাদ।
          </p>
        </marquee>
      </div>
      <div className="card w-full md:w-1/2 bg-base-100 shadow-xl">
        <div className="card-body">
          <p className="font-bold text-2xl">গুরত্বপূর্ণ বার্তা ঃ</p>
          <p>{notice[0].notice}</p>
        </div>
      </div>
      <div className="card w-full md:w-1/2 bg-base-100 shadow-xl mt-5 mb-12">
        <div className="card-body">
          <p className="font-bold text-2xl">বিস্তারিত নিয়মাবলী ঃ</p>
          <div style={{ fontFamily: "solaiman" }} className="text-justify">
            <div className="flex ">
              <div className=" ">
                <FaArrowCircleRight className=" text-[#9E0C00] mr-2 pt-1 text-2xl" />
              </div>
              <p>
                রেজিষ্ট্রেশন করার পর আপনার সকল তথ্য দিয়ে আপনার প্রোফাইলটি
                সম্পূর্ণ করুন। আমরা আপনার সকল তথ্য যাচাই করে আপনার সাথে যোগাযোগ
                করে প্রোফাইল পাবলিশ করব।
              </p>
            </div>
            <div className="flex mt-1">
              <div className=" ">
                <FaArrowCircleRight className=" text-[#9E0C00] mr-2 pt-1 text-2xl" />
              </div>
              <p>
                আপনার প্রোফাইলের যেকোনো তথ্য পরিবর্তন করলে এপ্রোব এর জন্য
                অপেক্ষা করতে হবে। আমরা আপনার সাথে যোগাযোগ করব।
              </p>
            </div>

            <div className="flex mt-1">
              <div className=" ">
                <FaArrowCircleRight className=" text-[#9E0C00] mr-2 pt-1 text-2xl" />
              </div>
              <p>
                আপনার রক্তদানের ছবি পোস্ট করার জন্য Add Post থেকে ছবি আপলোড
                করুন।
              </p>
            </div>
            <div className="flex mt-1">
              <div className=" ">
                <FaArrowCircleRight className=" text-[#9E0C00] mr-2 pt-1 text-2xl" />
              </div>
              <p>
                আপনার ফোন নাম্বারটি গোপন অথবা পাবলিক করতে চাইলে সেটিংস থেকে Only
                Me অথবা Public করুন।
              </p>
            </div>
            <div className="flex mt-1">
              <div className=" ">
                <FaArrowCircleRight className=" text-[#9E0C00] mr-2 pt-1 text-2xl" />
              </div>
              <p>পাসওয়ার্ড পরিবর্তন করতে সেটিংস এ যান।</p>
            </div>
            <div className="flex mt-1">
              <div className=" ">
                <FaArrowCircleRight className=" text-[#9E0C00] mr-2 pt-1 text-2xl" />
              </div>
              <p>
                ৫ বার এর বেশি রক্ত দান করলে তিনি পাবেন দুরন্ত রক্ত সৈনিক বেজ
                সম্মাননা।
              </p>
            </div>
            <div className="flex mt-1">
              <div className=" ">
                <FaArrowCircleRight className=" text-[#9E0C00] mr-2 pt-1 text-2xl" />
              </div>
              <p>
                সকল রক্তযোদ্ধা এর জন্মদিন আমাদের কাছে গুরুত্বপূর্ণ, আপনার জন্ম
                দিনে ওয়েব সাইটে আপনাকে ফিচার করে শুভেচ্ছা জানানো হবে।
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notice;
