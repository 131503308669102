import React from "react";
import "./LoadingCercle.css";

const LoadingCercle = () => {
  return (
    <div className="w-full h-[60vh] flex justify-center items-center">
      <div class="loadingio-spinner-spinner-977el9wwy2v">
        <div class="ldio-4j5ay0xf86g">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default LoadingCercle;
