import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useQuery } from "react-query";
import donation from "../../Assets/donation.jpg";
import Loading from "../../Shared/Loading";
import FamilyMember from "./FamilyMember";

const AboutUs = () => {
  const [upodeshtaMember, setupodeshtaMember] = useState([]);
  let url = `https://rokto-bondon-server.vercel.app/all-family-member`;
  const {
    data: members,
    isLoading,
    refetch,
  } = useQuery("memberofFamily", () => fetch(url).then((res) => res.json()));

  const family = members?.filter((member) => member.catagories !== "upodeshta");
  useEffect(() => {
    const upodeshta = members?.filter(
      (member) => member.catagories === "upodeshta"
    );
    setupodeshtaMember(upodeshta);
  }, [members]);

  const bgColor = "#004238";

  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);

  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 15;

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(family?.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(family?.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, family]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % family?.length;
    setItemOffset(newOffset);
  };
  const textColor = "";
  if (isLoading) {
    return <Loading></Loading>;
  }
  return (
    <div className="md:px-16 px-2">
      <div className="text-center mt-12 mb-8">
        <p className=" text-2xl md:text-5xl text-center">আমাদের সম্পর্কে</p>
        <span className="divider mx-auto mt-0  w-16"></span>
      </div>
      <div className="w-full md:w-2/3 mx-auto grid md:grid-cols-2 grid-cols-1 gap-8 p-3 rounded-lg bg-slate-900">
        <div>
          <img className="w-full" src={donation} />
        </div>
        <div className="text-white">
          <p className=" text-xl my-1">
            একের রক্ত অন্যের জীবন রক্তই হোক আত্মার বন্ধন।
          </p>
          <p>১. বিনামূল্যে রক্তের গ্রুপ নির্ণয় কর্মসূচি</p>
          <p>২. ফ্রি মেডিকেল ক্যাম্প</p>
          <p>৩. স্বেচ্ছায় রক্তদান নিয়ে সচেতনতা তৈরি</p>
          <p>৪. জাতীয় ও আন্তর্জাতিক দিবস পালন </p>
          <p>৫. বিভিন্ন সামাজিক কর্মকান্ড পালন</p>
          <p>৬. পথ শিশুদের বিদ্যালয়ে যাওয়ার ব্যবস্থা করা</p>
          <p>৭. গরীব অসহায় মানুষকে বিভিন্নভাবে সাহায্য করা</p>
          <p>৮. বাল্যবিবাহ ও মাদক রোধ করা নিয়ে সচেতনতা তৈরি</p>
        </div>
      </div>

      {/* our upodesta */}
      <div>
        <div className="text-center mt-12 mb-8">
          <p className=" text-2xl md:text-5xl text-center">উপদেষ্টা মন্ডলী</p>
          <span className="divider mx-auto mt-0  w-16"></span>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-4 md:grid-cols-3 xl:grid-cols-5 gap-12 mb-20">
          {upodeshtaMember?.map((member) => {
            return (
              <FamilyMember
                key={member._id}
                member={member}
                bgColor={bgColor}
              ></FamilyMember>
            );
          })}
        </div>
      </div>
      {/* our mangement */}
      <div>
        <div className="text-center mt-12 mb-8">
          <p className=" text-2xl md:text-5xl text-center">আমাদের পরিবার</p>
          <span className="divider mx-auto mt-0  w-16"></span>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-4 md:grid-cols-3 xl:grid-cols-5 gap-12 mb-20">
          {currentItems?.map((member) => {
            return (
              <FamilyMember key={member._id} member={member}></FamilyMember>
            );
          })}
        </div>
      </div>
      {/* paginate */}
      <div>
        <ReactPaginate
          breakLabel="..."
          nextLabel=">>"
          onPageChange={handlePageClick}
          pageRangeDisplayed={1}
          marginPagesDisplayed={1}
          pageCount={pageCount}
          previousLabel="<<"
          renderOnZeroPageCount={null}
          containerClassName="btn-group pagination"
          pageLinkClassName="btn btn-sm"
          previousLinkClassName="btn-sm btn"
          nextLinkClassName="btn btn-sm"
          activeClassName="pagination-active"
        />
      </div>
    </div>
  );
};

export default AboutUs;
