import React from "react";
import { Link } from "react-router-dom";
import Badges from "./Badges/Badges";

const BestSinglneDonner = ({ donner }) => {
  const {
    fullName,
    email,
    img,
    bloodGroup,
    distric,
    address,
    _id,
    donationCount,
  } = donner;

  return (
    <div className="">
      <div className="our-team">
        {donationCount > 5 ? <Badges></Badges> : ""}
        <div className="picture">
          <img
            className="img-fluid"
            src={
              donner?.img
                ? donner?.img
                : "https://upload.wikimedia.org/wikipedia/commons/a/aa/Sin_cara.png"
            }
          />
        </div>
        <div className="team-content">
          <h3 className="text-md text-black capitalize">{fullName}</h3>
          <h4 className="text-xl font-bold text-gray-800">{bloodGroup}</h4>
          <p className="text-md text-gray-800">{distric}</p>
        </div>
        <ul className="profile-footer">
          <Link to={`/donner/${_id}`}>View Profile</Link>
        </ul>
      </div>
    </div>
  );
};

export default BestSinglneDonner;
