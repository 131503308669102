import React from "react";
import { FaFacebookSquare } from "react-icons/fa";
import { FaEnvelope } from "react-icons/fa";
import { FaPhoneSquareAlt } from "react-icons/fa";
import { FaSearchLocation } from "react-icons/fa";
import { FaHandHoldingWater } from "react-icons/fa";
import { FaBirthdayCake } from "react-icons/fa";
import { FaUserFriends } from "react-icons/fa";
import { FaHandHoldingHeart } from "react-icons/fa";
import { HiLocationMarker } from "react-icons/hi";
import ImageGallary from "./ImageGallary";
import ContactForm from "./ContactForm";
import { Link } from "react-router-dom";

const DonnerProfile = ({ donner }) => {
  return (
    <div className="">
      <div className="h-48 md:h-70 donner-cover bg-black overflow-hidden">
        <div className="cover-body">
          <div class="drop drop-margin"></div>

          <div class="wave drop-margin"> </div>
          <p className="cover-title">
            একের রক্ত অন্যের জীবন <br />
            রক্তই হোক আত্মার বন্ধন।
          </p>
        </div>
      </div>
      {/* avatar and profile main info */}
      <div className="block md:flex py-5 bg-gray-600">
        <div className="md:w-72 w-2/3 ">
          <div
            className={`avatar mt-[-100px] md:ml-20 ml-5 ${
              donner?.elegibale == "Yes" || donner?.elegibale == true
                ? "online"
                : "offline"
            }`}
          >
            <div className="w-42 rounded-full ring ring-white ring-offset-base-100 ring-offset-2">
              <img
                src={
                  donner?.img
                    ? donner?.img
                    : "https://cdn.vectorstock.com/i/preview-1x/22/05/male-profile-picture-vector-1862205.jpg"
                }
              />
            </div>
          </div>
        </div>
        <div className="flex justify-between w-full">
          <div className="md:ml-8 ml-5 md:mt-0 mt-5">
            <p className="text-4xl font-bold text-white">{donner?.fullName}</p>

            <p className="text-xl text-white">
              Blood Group: {donner?.bloodGroup}
            </p>
            <div className="block md:flex">
              <span className="text-sm flex items-center">
                <HiLocationMarker className="text-green-500 mr-1" />
                <span className="text-white">{donner?.distric}</span>
              </span>
              <span className="text-sm flex items-center ml-0 md:ml-7">
                <FaFacebookSquare className="text-blue-500 mr-1" />
                <a
                  href={`${donner?.fbID}`}
                  target="_blank"
                  className="text-white"
                >
                  Profile
                </a>
              </span>
            </div>
          </div>
          <div className="mt-6 md:mr-8 mr-1 pt-12">
            <div className=" flex items-center gap-2">
              <span className="w-4 h-4 bg-[#008000] rounded-full block"></span>
              <span className="text-white">রক্তদানে সমর্থ</span>
            </div>
            <div className=" flex items-center gap-2 mt-1 mr-3">
              <span className="w-4 h-4 bg-[#9E0C00] rounded-full block"></span>
              <span className="text-white">রক্তদানে অসমর্থ</span>
            </div>
          </div>
        </div>
  
      </div>

      <div className="md:flex block">
        <div className="md:w-[420px] w-full  bg-gray-500 py-16 px-8">
          <p className="font-semibold flex items-center text-white">
            <div className="w-8 h-8 main-color p-2 rounded-sm mr-2">
              <FaHandHoldingHeart className="text-white mr-1" />
            </div>
            Last Donation Date: {donner?.lastDonationDate}
          </p>
          <p className="flex items-center text-white mt-4 font-semibold">
            <div className="w-8 h-8 main-color p-2 rounded-sm mr-2">
              <FaHandHoldingWater className="text-white mr-1" />
            </div>
            <span>Donation: {donner?.donationCount}</span>
          </p>
          <p className="flex items-center text-white mt-4 font-semibold">
            <div className="w-8 h-8 main-color p-2 rounded-sm mr-2">
              <FaPhoneSquareAlt className="text-white mr-1" />
            </div>
            <a
              href={`tel: ${
                !donner?.showPhone ? "01902166881" : donner?.phone
              }`}
            >
              {!donner?.showPhone ? "01902166881" : donner?.phone}
            </a>
          </p>
          <p className="flex items-center text-white mt-4 font-semibold">
            <div className="w-8 h-8 main-color p-2 rounded-sm mr-2">
              <FaEnvelope className="text-white mr-1" />
            </div>
            <span>{donner?.email}</span>
          </p>
          <p className="flex items-center text-white mt-4 font-semibold">
            <div className="w-8 h-8 main-color p-2 rounded-sm mr-2">
              <FaBirthdayCake className="text-white mr-1 " />
            </div>
            <span>Birthday: {donner?.birthday}</span>
          </p>
          <p className="flex items-center text-white mt-4 font-semibold">
            <div className="w-8 h-8 main-color p-2 rounded-sm mr-2">
              <FaSearchLocation className="text-white mr-1 " />
            </div>
            <span>Distric: {donner?.distric}</span>
          </p>
          <p className="flex items-center text-white mt-4 font-semibold">
            <div className="w-8 h-8 main-color p-2 rounded-sm mr-2">
              <HiLocationMarker className="text-white mr-1 " />
            </div>
            <span>{donner?.address}</span>
          </p>
          <p className="flex items-center text-white mt-4 font-semibold">
            <div className="w-8 h-8 main-color p-2 rounded-sm mr-2">
              <FaUserFriends className="text-white mr-1 " />
            </div>
            <span>{donner?.gender}</span>
          </p>
        </div>
        <div className="p-8 grid grid-cols-1 md:grid-cols-2 gap-5">
          <ImageGallary email={donner?.email}></ImageGallary>
          <ContactForm donner={donner}></ContactForm>
        </div>
      </div>
    </div>
  );
};

export default DonnerProfile;
