import React, { useEffect, useState } from "react";
import ReactImageGallery from "react-image-gallery";
import { useQuery } from "react-query";
import Loading from "../Shared/Loading";

const ImageGallary = ({ email }) => {
  const [loading, setLoading] = useState(true);
  const [donnerPostImages, setdonnerPostImages] = useState([]);

  const url = `https://rokto-bondon-server.vercel.app/posts/${email}`;

  /*  const {
    data: donnerPostImages,
    isLoading,
    refetch,
  } = useQuery("donnerProfileImageGallary", () =>
    fetch(url).then((res) => res.json())
  ); */
  console.log("form imga galar", donnerPostImages);
  useEffect(() => {
    setLoading(true);
    if (email) {
      //  const url = `https://rokto-bondon-server.vercel.app/posts/${email}`;
      fetch(url)
        .then((res) => res.json())
        .then((data) => {
          setdonnerPostImages(data);
          setLoading(false);
        });
    }
  }, [email]);
  /* 
  useEffect(() => {
    setLoading(true);
    if (email) {
      setLoading(true);
      refetch();
      setLoading(false);
    }
    setLoading(false);
  }, [email]); */

  if (loading) return <Loading></Loading>;

  return (
    <div>
      <h3 className="text-4xl font-bold inline-block">My Posts </h3>
      <span className="divider my-2"></span>
      <ReactImageGallery autoPlay items={donnerPostImages} />
    </div>
  );
};

export default ImageGallary;
