import React from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import Loading from "../../Shared/Loading";

const SinglePageActivity = () => {
  const { idName } = useParams();
  const url = `https://rokto-bondon-server.vercel.app/single-page-post/${idName}`;

  const {
    data: post,
    isLoading,
    reFetch,
  } = useQuery("activity-signle-post", () =>
    fetch(url).then((res) => res.json())
  );
  console.log(post);

  if (isLoading) {
    return <Loading></Loading>;
  }

  return (
    <div className="md:w-2/3 w-full mx-auto px-2 md:px-7 py-16 shadow-lg">
      <p className="md:text-5xl md:leading-[67px] text-3xl font-bold mb-7 text-black md:text-white text-height">
        {post?.title}
      </p>

      <img src={post?.image} className="md:w-4/5 w-full block" />
      <div
        className="my-8 activity-sigle-post"
        id="solaiman-font"
        dangerouslySetInnerHTML={{ __html: post?.content }}
      />
    </div>
  );
};

export default SinglePageActivity;
