import { signOut } from "firebase/auth";
import React from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link } from "react-router-dom";
import auth from "../firebase.init";
import "./Shared.css";
import { useQuery } from "react-query";
import logo from "../Assets/footer-logo.png";

const Header = () => {
  const [user] = useAuthState(auth);

  const url = `https://rokto-bondon-server.vercel.app/my-profile/${user?.email}`;

  const {
    data: profileImage,
    isLoading,
    reFetch,
  } = useQuery("myProfileDataforMy", () =>
    fetch(url).then((res) => res.json())
  );

  const bloodDoner = (
    <>
      <li>
        <Link to="/bloodgroup/a+">A+ (A POSITIVE)</Link>
      </li>
      <li>
        <Link to="/bloodgroup/a-">A- (A NEGATIVE)</Link>
      </li>
      <li>
        <Link to="/bloodgroup/b+">B+ (B POSITIVE)</Link>
      </li>
      <li>
        <Link to="/bloodgroup/b-">B- (B NEGATIVE)</Link>
      </li>
      <li>
        <Link to="/bloodgroup/ab+">AB+ (AB POSITIVE)</Link>
      </li>
      <li>
        <Link to="/bloodgroup/ab-">AB- (AB NEGATIVE)</Link>
      </li>
      <li>
        <Link to="/bloodgroup/o+">O+ (O POSITIVE)</Link>
      </li>
      <li>
        <Link to="/bloodgroup/o-">O- (O NEGATIVE)</Link>
      </li>
    </>
  );

  const dashboard = (
    <>
      <div className="dropdown dropdown-end ml-auto md:ml-0 ">
        <label tabindex="0" className="btn btn-ghost btn-circle avatar">
          <div className="w-10 rounded-full">
            <img
              src={
                profileImage?.img
                  ? profileImage?.img
                  : "https://via.placeholder.com/150/771796"
              }
            />
          </div>
        </label>

        <ul
          tabindex="0"
          className="mt-3 p-2 shadow menu menu-compact dropdown-content bg-base-100 rounded-box w-52"
        >
          <li>
            <Link to={`/dashboard/myprofile/${user?.email}`}>My Profile</Link>
          </li>
          <li>
            <Link to={"/dashboard/edit-profile"}>Edit Profile</Link>
          </li>
          <li>
            <Link to={"/dashboard/"}>Notice</Link>
            <Link to={"/dashboard/add-post"}>Add Post</Link>
            <Link to={"/dashboard/setting"}>Settings</Link>
          </li>
          <li onClick={() => signOut(auth)}>
            <a>Logout</a>
          </li>
        </ul>
      </div>
    </>
  );
  const login = (
    <>
      <li>
        <Link to="/signup">রেজিস্ট্রেশন করুন</Link>
      </li>
      <li>
        <Link to="/login">লগইন</Link>
      </li>
    </>
  );

  const secondMenu = (
    <>
      <li>
        <Link to="/serachresult">সার্চ</Link>
      </li>
      <li>
        <Link to="/all-social">সহযোগী সংগঠন</Link>
      </li>
      <li>
        <Link to="/our-activity">আমাদের কার্যক্রম</Link>
      </li>
      <li>
        <Link to="/about-us">আমাদের সম্পর্কে</Link>
      </li>
    </>
  );

  return (
    <div className="navbar my-nav bg-[#9E0C00]">
      <div className="navbar-start">
        <div className="dropdown ">
          <label
            tabindex="0"
            className=" btn btn-ghost lg:hidden   "
            style={{ background: "rgba(0,0,0,.2)" }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 text-white border-0"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h8m-8 6h16"
              />
            </svg>
          </label>
          <ul
            tabindex="0"
            className="menu menu-compact dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box w-52"
          >
            <li>
              <Link to="/">হোম</Link>
            </li>
            <li tabindex="0">
              <Link to="/" className="justify-between">
                রক্তের গ্রুপ
                <svg
                  className="fill-current"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" />
                </svg>
              </Link>
              <ul className="p-2 blood-doner text-white">{bloodDoner}</ul>
            </li>
            {secondMenu}
            {!user && login}
          </ul>
        </div>
        <Link
          to="/"
          className="btn btn-ghost normal-case text-2xl md:text-left text-center flex mt-[-15px]  text-white"
        >
          <img src={logo} className="w-[58px]" />
          <p> বন্ধন রক্তদান সংস্থা</p>
        </Link>
      </div>
      <div className="navbar-end hidden lg:flex">
        <ul className="menu menu-horizontal p-0 text-white">
          <li>
            <Link to="/">হোম</Link>
          </li>
          <li tabindex="0">
            <Link to="/">
              রক্তের গ্রুপ
              <svg
                className="fill-current"
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
              >
                <path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" />
              </svg>
            </Link>
            <ul className="p-2 blood-doner text-white">{bloodDoner}</ul>
          </li>
          {secondMenu}

          {!user && login}
        </ul>
      </div>

      {user && dashboard}
    </div>
  );
};

export default Header;
