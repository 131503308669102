import Activity from "./Activity";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useQuery } from "react-query";
import Loading from "../../Shared/Loading";

const OurActivites = () => {
  let url = `https://rokto-bondon-server.vercel.app/all-post`;

  const {
    data: posts,
    isLoading,
    reFetch,
  } = useQuery("our-activities", () => fetch(url).then((res) => res.json()));

  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);

  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 8;

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(posts?.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(posts?.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, posts]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % posts?.length;
    setItemOffset(newOffset);
  };

  if (isLoading) {
    return <Loading></Loading>;
  }

  return (
    <div className="md:px-16 px-2">
      <div>
        <div className="text-center mt-12 mb-8">
          <p className=" text-2xl md:text-5xl text-center">আমাদের কার্যক্রম</p>
          <span className="divider mx-auto mt-0  w-16"></span>
        </div>
        <div className="grid grid-cols-1  md:grid-cols-3 gap-8 mb-20">
          {currentItems?.map((post) => (
            <Activity key={post?._id} post={post}></Activity>
          ))}
        </div>
      </div>

      {/* paginate */}
      <div>
        <ReactPaginate
          breakLabel="..."
          nextLabel=">>"
          onPageChange={handlePageClick}
          pageRangeDisplayed={1}
          marginPagesDisplayed={1}
          pageCount={pageCount}
          previousLabel="<<"
          renderOnZeroPageCount={null}
          containerClassName="btn-group pagination"
          pageLinkClassName="btn btn-sm"
          previousLinkClassName="btn-sm btn"
          nextLinkClassName="btn btn-sm"
          activeClassName="pagination-active"
        />
      </div>
    </div>
  );
};

export default OurActivites;
