import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useQuery } from "react-query";
import ScetelonLoading from "../../Shared/AllLoading/ScetelonLoading";
import HomeHeading from "../../Shared/HomeHeading";
import Loading from "../../Shared/Loading";
import BestSinglneDonner from "./BestSinglneDonner";

const BestBloodDonner = ({}) => {
  const {
    data: bestDonner,
    isLoading,
    refetch,
  } = useQuery("bestDonner", () =>
    fetch(`https://rokto-bondon-server.vercel.app/durdantodonnerhome`).then(
      (res) => res.json()
    )
  );

  return (
    <div className="md:px-16 px-3 md:pb-12 pb-5">
      <HomeHeading>
        দূর্দান্ত রক্ত সৈনিকগন
        <br />
        <p className="text-sm" id="solaiman-font">
          সর্বোচ্চ রক্তদানকারী রক্তযোদ্ধাগন
        </p>
      </HomeHeading>
      {isLoading && (
        <>
          <div className="grid md:grid-cols-3 lg:grid-cols-5 grid-cols-2 gap-4 py-5">
            {[1, 1, 1, 1, 1, 1, 1, 1, 1, 1].map((e, index) => (
              <ScetelonLoading key={index} />
            ))}
          </div>
        </>
      )}
      <div className="mt-5 md:pt-5 pt-0">
        <div className="grid md:grid-cols-3 lg:grid-cols-5 grid-cols-2 gap-4">
          {bestDonner?.map((donner) => (
            <BestSinglneDonner
              key={donner._id}
              donner={donner}
            ></BestSinglneDonner>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BestBloodDonner;
