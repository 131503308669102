import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import BestSinglneDonner from "../Home/BestSinglneDonner";
import ReactPaginate from "react-paginate";
import LoadingCercle from "../../Shared/AllLoading/LoadingCercle";

const SerachResult = ({ donners, setDonners }) => {
  const [startDate, setStartDate] = useState(new Date());

  const [loading, setLoading] = useState(false);

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();
  //   const date = format(startDate, "PP");

  const handleSearch = (data) => {
    setLoading(true);
    const url = `https://rokto-bondon-server.vercel.app/serachresult/${data.bloodGroup}/?distric=${data.distric}&type=${data.elegiable}`;
    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        setDonners(data);
        setLoading(false);
      })
      .finally(() => setLoading(false));
  };

  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);

  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 12;

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(donners?.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(donners?.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, donners]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % donners?.length;
    setItemOffset(newOffset);
  };

  const handleAdvanceSerach = (e) => {
    const data = e.target.value;
    if (data.length < 2) {
      setLoading(false)
      return setDonners([]);
    }
    setLoading(true);

    const advanceUrl = `https://rokto-bondon-server.vercel.app/advanced-search/?value=${data}`;
    fetch(advanceUrl)
      .then((res) => res.json())
      .then((data) => {
        setDonners(data);
        setLoading(false);
      })
      .finally(() => setLoading(false));
  };

  return (
    <div className="mb-40">
      <div>
        <div className="my-16 md:px-16 px-3">
          <div className="my-8">
            <div className="text-center divider">
              <h3 className="animate-charcter text-xl md:text-3xl">
                রক্তদাতা খুঁজে নিন এখান থেকে
              </h3>
            </div>
          </div>
          <form
            style={{ justifyItems: "center" }}
            onSubmit={handleSubmit(handleSearch)}
            className="grid grid-cols-1 md:grid-cols-5 items-end gap-4"
          >
            <div className="form-control w-full max-w-xs">
              <label className="label" htmlFor="">
                <span className="label-text">রক্তের গ্রুপ</span>
              </label>
              <select
                {...register("bloodGroup")}
                className="select select-bordered w-full max-w-xs"
              >
                <option value={"all"} selected>
                  All
                </option>
                <option>A+</option>
                <option>A-</option>
                <option>B+</option>
                <option>B-</option>
                <option>AB+</option>
                <option>AB-</option>
                <option>O+</option>
                <option>O-</option>
              </select>
            </div>
            <div className="form-control w-full max-w-xs">
              <label className="label" htmlFor="">
                <span className="label-text">জেলা</span>
              </label>
              <select
                {...register("distric")}
                className="select select-bordered w-full max-w-xs"
              >
                <option value={"all"} selected>
                  All
                </option>
                <option>Dhaka</option>
                <option>Faridpur</option>
                <option>Gazipur</option>
                <option>Gopalganj</option>
                <option>Jamalpur</option>
                <option>Kishoreganj</option>
                <option>Madaripur</option>
                <option>Manikganj</option>
                <option>Munshiganj</option>
                <option>Mymensingh</option>
                <option>Narayanganj</option>
                <option>Narsingdi</option>
                <option>Netrokona</option>
                <option>Rajbari</option>
                <option>Shariatpur</option>
                <option>Sherpur</option>
                <option>Tangail</option>
                <option>Bogra</option>
                <option>Joypurhat</option>
                <option>Naogaon</option>
                <option>Natore</option>
                <option>Nawabganj</option>
                <option>Pabna</option>
                <option>Rajshahi</option>
                <option>Sirajgonj</option>
                <option>Dinajpur</option>
                <option>Gaibandha</option>
                <option>Kurigram</option>
                <option>Lalmonirhat</option>
                <option>Nilphamari</option>
                <option>Panchagarh</option>
                <option>Rangpur</option>
                <option>Thakurgaon</option>
                <option>Barguna</option>
                <option>Barisal</option>
                <option>Bhola</option>
                <option>Jhalokati</option>
                <option>Patuakhali</option>
                <option>Pirojpur</option>
                <option>Bandarban</option>
                <option>Brahmanbaria</option>
                <option>Chandpur</option>
                <option>Chittagong</option>
                <option>Comilla</option>
                <option>Cox</option>''s Bazar
                <option>Feni</option>
                <option>Khagrachari</option>
                <option>Lakshmipur</option>
                <option>Noakhali</option>
                <option>Rangamati</option>
                <option>Habiganj</option>
                <option>Maulvibazar</option>
                <option>Sunamganj</option>
                <option>Sylhet</option>
                <option>Bagerhat</option>
                <option>Chuadanga</option>
                <option>Jessore</option>
                <option>Jhenaidah</option>
                <option>Khulna</option>
                <option>Kushtia</option>
                <option>Magura</option>
                <option>Meherpur</option>
                <option>Narail</option>
                <option>Satkhira</option>
              </select>
            </div>
            <div className="form-control w-full max-w-xs">
              <label className="label" htmlFor="">
                <span className="label-text">এডভান্ডস ‍র্সাচ</span>
              </label>
              <input
                type={"text"}
                className="input input-bordered w-full max-w-xs"
                onChange={handleAdvanceSerach}
                placeholder="Name/Email/phone/Address"
              />
            </div>
            <div className="form-control w-full max-w-xs">
              <label className="label" htmlFor="">
                <span className="label-text">রক্তদাতার ধরণ</span>
              </label>
              <select
                {...register("elegiable")}
                className="select select-bordered w-full max-w-xs"
              >
                <option value={"all"} selected>
                  All
                </option>
                <option value={"all"}>সকল রক্তদাতা</option>
                <option value={"yes"}>রক্তদানে সমর্থ</option>
              </select>
            </div>
            <div className="form-control w-full max-w-xs">
              <input
                type={"submit"}
                className="btn bg-[#231557]"
                value={"Search"}
              />
            </div>
          </form>
        </div>
      </div>
 
        <p className="text-sm md:px-12 px-2 pb-4">
          সর্বমোট রক্তদাতা সংখ্যা: {donners?.length}
        </p>
    
      {loading ? (
        <LoadingCercle />
      ) : (
        <div className="grid md:grid-cols-3 lg:grid-cols-6 grid-cols-2 gap-4 md:px-12 px-2">
          {currentItems?.map((donner) => (
            <BestSinglneDonner
              key={donner._id}
              donner={donner}
            ></BestSinglneDonner>
          ))}
        </div>
      )}
      {/* paginate */}
      <div>
        <ReactPaginate
          breakLabel="..."
          nextLabel=">>"
          onPageChange={handlePageClick}
          pageRangeDisplayed={1}
          marginPagesDisplayed={1}
          pageCount={pageCount}
          previousLabel="<<"
          renderOnZeroPageCount={null}
          containerClassName="btn-group pagination"
          pageLinkClassName="btn btn-sm"
          previousLinkClassName="btn-sm btn"
          nextLinkClassName="btn btn-sm"
          activeClassName="pagination-active"
        />
      </div>
    </div>
  );
};

export default SerachResult;
