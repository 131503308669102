import React from "react";
import Heading from "../../Shared/Heading";
import AddPostSection from "./AddPostSection";
import DonnerMyPost from "./DonnerMyPost";
import { useQuery } from "react-query";
import { useAuthState } from "react-firebase-hooks/auth";
import auth from "../../firebase.init";
import DasboardMenuHambarg from "../../Shared/DasboardMenuHambarg";

const AddPost = () => {
  const [user, loading] = useAuthState(auth);
  const url = `https://rokto-bondon-server.vercel.app/posts-donner/${user?.email}`;

  const {
    data: donnnerPost,
    isLoading,
    refetch,
  } = useQuery("donnerMyProilfe", () => fetch(url).then((res) => res.json()));
  return (
    <div>
      <Heading>
        <div className="flex justify-between items-center">
          <span>Add Post</span>
          <DasboardMenuHambarg></DasboardMenuHambarg>
        </div>
      </Heading>
      <AddPostSection refetch={refetch}></AddPostSection>
      <Heading>My All Photo</Heading>
      <DonnerMyPost
        donnnerPost={donnnerPost}
        isLoading={isLoading}
        refetch={refetch}
      ></DonnerMyPost>
    </div>
  );
};

export default AddPost;
