import { updateProfile } from "firebase/auth";
import React, { useEffect, useState } from "react";
import {
  useAuthState,
  useCreateUserWithEmailAndPassword,
} from "react-firebase-hooks/auth";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import auth from "../../firebase.init";
import Loading from "../../Shared/Loading";
import DatePicker from "react-datepicker";

import { format } from "date-fns";
import swal from "sweetalert";
import { async } from "@firebase/util";

const Signup = () => {
  const [startDate, setStartDate] = useState(new Date());

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const date = format(startDate, "PP");

  const [createUserWithEmailAndPassword, user, loading, error] =
    useCreateUserWithEmailAndPassword(auth);

  const navigate = useNavigate();

  const HandleUpdateProfile = async (data) => {
    if (data.email) {
      fetch(`https://rokto-bondon-server.vercel.app/check-signup/${data.email}`)
        .then((res) => res.json())
        .then((result) => {
          if (result.data) {
            alert("your email already used");
            return;
          } else if (!result.data) {
            createUserWithEmailAndPassword(data.email, data.password);

            const updateDonner = {
              fullName: data?.fullName,
              bloodGroup: data?.bloodGroup,
              distric: data?.distric,
              address: data?.address,
              donationCount: data?.donationCount,
              email: data?.email,
              phone: data?.phone,
              birthday: date,
              elegibale: data?.elegibale,
              gender: data?.gender,
              request: true,
              showPhone: true,
              lastDonationDate: data?.lastDonationDate,
            };
            fetch(
              `https://rokto-bondon-server.vercel.app/approvedRequest/${user?.email}`,
              {
                method: "PUT",
                headers: {
                  "content-type": "application/json",
                },
                body: JSON.stringify(updateDonner),
              }
            )
              .then((res) => res.json())
              .then((sign) => {
                if (
                  sign?.upsertedId ||
                  !sign?.modifiedCount ||
                  sign.acknowledged
                ) {
                  swal(
                    "success",
                    "আপনার প্রোফাইল ইনফরমেশন আপডেট হয়েছে, ২৪ ঘন্টার মধ্যে যাচাই করে পাবলিশ করা হবে",
                    "success"
                  );
                  navigate("/dashboard/");
                }
              });
          }
        });
    }
  };
  if (user) {
    navigate("/dashboard/");
  }
  if (loading) {
    return <Loading></Loading>;
  }
  return (
    <>
      <div
        className="hero bg-base-200 md:px-0 px-3 md:p-5 "
        style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px;" }}
      >
        <div className="w-full md:w-1/2 ">
          <h1 className="text-5xl my-3 mt-5 font-bold ">রেজিষ্ট্রেশন করুন</h1>
          <form onSubmit={handleSubmit(HandleUpdateProfile)}>
            <div className="grid md:grid-cols-2 gap-0 md:gap-5">
              <div>
                <div className="form-control">
                  <label className="label">
                    <span className="label-text">
                      নাম <span className="text-red-400">*</span>
                    </span>
                  </label>

                  <input
                    type={"text"}
                    {...register("fullName")}
                    placeholder="Full Name"
                    className="input input-bordered"
                    required
                  />
                  <small className="text-red-500">{errors.name?.message}</small>
                </div>
                <div className="form-control">
                  <label className="label">
                    <span className="label-text">
                      ইমেইল <span className="text-red-400">*</span>
                    </span>
                  </label>

                  <input
                    type={"email"}
                    {...register("email", {
                      required: "Email Address is required",
                    })}
                    placeholder="email"
                    className="input input-bordered"
                  />
                  <small className="text-red-500">
                    {errors.email?.message}
                  </small>
                </div>
                <div className="form-control">
                  <label className="label">
                    <span className="label-text">
                      পাসওয়ার্ড <span className="text-red-400">*</span>
                    </span>
                  </label>
                  <input
                    type={"password"}
                    {...register("password", {
                      required: "password Address is required",
                    })}
                    placeholder="Password"
                    className="input input-bordered"
                  />
                  <small className="text-red-500">
                    {errors.password?.message}
                  </small>
                </div>
                <div className="form-control w-full">
                  <label className="label">
                    <span className="label-text">
                      রক্তের গ্রুপ<span className="text-red-400">*</span>{" "}
                    </span>
                  </label>
                  <select
                    {...register("bloodGroup", { required: true })}
                    className="select select-bordered"
                  >
                    <option selected hidden>
                      select
                    </option>
                    <option value={"A+"}>A+</option>
                    <option value={"A-"}>A-</option>
                    <option value={"B+"}>B+</option>
                    <option value={"B-"}>B-</option>
                    <option value={"AB+"}>AB+</option>
                    <option value={"AB-"}>AB-</option>
                    <option value={"O+"}>O+</option>
                    <option value={"O-"}>O-</option>
                  </select>
                  <label className="label">
                    <span className="label-text-alt text-red-600">
                      {errors.bloodGroup?.type === "required" &&
                        "please Select any blood group"}
                    </span>
                  </label>
                </div>
                <div className="form-control w-full ">
                  <label className="label">
                    <span className="label-text">
                      মোবাইল নাম্বার <span className="text-red-400">*</span>
                    </span>
                  </label>

                  <input
                    {...register("phone", { required: true })}
                    type="text"
                    placeholder="Type here"
                    className="input input-bordered w-full"
                  />
                </div>

                <div className="form-control w-full">
                  <label className="label">
                    <span className="label-text">
                      জেলা <span className="text-red-400">*</span>
                    </span>
                  </label>
                  <select
                    {...register("distric", { required: true })}
                    className="select select-bordered"
                  >
                    <option selected hidden>
                      select
                    </option>
                    <option value={"Chandpur"}>Chandpur</option>
                    <option value={"Dhaka"}>Dhaka</option>
                    <option value={"Faridpur"}>Faridpur</option>
                    <option value={"Gazipur"}>Gazipur</option>
                    <option value={"Gopalganj"}>Gopalganj</option>
                    <option value={"Jamalpur"}>Jamalpur</option>
                    <option value={"Kishoreganj"}>Kishoreganj</option>
                    <option value={"Madaripur"}>Madaripur</option>
                    <option value={"Manikganj"}>Manikganj</option>
                    <option value={"Munshiganj"}>Munshiganj</option>
                    <option value={"Mymensingh"}>Mymensingh</option>
                    <option value={"Narayanganj"}>Narayanganj</option>
                    <option value={"Narsingdi"}>Narsingdi</option>
                    <option value={"Netrokona"}>Netrokona</option>
                    <option value={"Rajbari"}>Rajbari</option>
                    <option value={"Shariatpur"}>Shariatpur</option>
                    <option value={"Sherpur"}>Sherpur</option>
                    <option value={"Tangail"}>Tangail</option>
                    <option value={"Bogra"}>Bogra</option>
                    <option value={"Joypurhat"}>Joypurhat</option>
                    <option value={"Naogaon"}>Naogaon</option>
                    <option value={"Natore"}>Natore</option>
                    <option value={"Nawabganj"}>Nawabganj</option>
                    <option value={"Pabna"}>Pabna</option>
                    <option value={"Rajshahi"}>Rajshahi</option>
                    <option value={"Sirajgonj"}>Sirajgonj</option>
                    <option value={"Dinajpur"}>Dinajpur</option>
                    <option value={"Gaibandha"}>Gaibandha</option>
                    <option value={"Kurigram"}>Kurigram</option>
                    <option value={"Lalmonirhat"}>Lalmonirhat</option>
                    <option value={"Nilphamari"}>Nilphamari</option>
                    <option value={"Panchagarh"}>Panchagarh</option>
                    <option value={"Rangpur"}>Rangpur</option>
                    <option value={"Thakurgaon"}>Thakurgaon</option>
                    <option value={"Barguna"}>Barguna</option>
                    <option value={"Barisal"}>Barisal</option>
                    <option value={"Bhola"}>Bhola</option>
                    <option value={"Jhalokati"}>Jhalokati</option>
                    <option value={"Patuakhali"}>Patuakhali</option>
                    <option value={"Pirojpur"}>Pirojpur</option>
                    <option value={"Bandarban"}>Bandarban</option>
                    <option value={"Brahmanbaria"}>Brahmanbaria</option>
                    <option value={"Chittagong"}>Chittagong</option>
                    <option value={"Comilla"}>Comilla</option>
                    <option value={"Cox"}>Cox</option>''s Bazar
                    <option value={"Feni"}>Feni</option>
                    <option value={"Khagrachari"}>Khagrachari</option>
                    <option value={"Lakshmipur"}>Lakshmipur</option>
                    <option value={"Noakhali"}>Noakhali</option>
                    <option value={"Rangamati"}>Rangamati</option>
                    <option value={"Habiganj"}>Habiganj</option>
                    <option value={"Maulvibazar"}>Maulvibazar</option>
                    <option value={"Sunamganj"}>Sunamganj</option>
                    <option value={"Sylhet"}>Sylhet</option>
                    <option value={"Bagerhat"}>Bagerhat</option>
                    <option value={"Chuadanga"}>Chuadanga</option>
                    <option value={"Jessore"}>Jessore</option>
                    <option value={"Jhenaidah"}>Jhenaidah</option>
                    <option value={"Khulna"}>Khulna</option>
                    <option value={"Kushtia"}>Kushtia</option>
                    <option value={"Magura"}>Magura</option>
                    <option value={"Meherpur"}>Meherpur</option>
                    <option value={"Narail"}>Narail</option>
                    <option value={"Satkhira"}>Satkhira</option>
                  </select>
                  <label className="label">
                    <span className="label-text-alt text-red-600">
                      {errors.distric?.type === "required" &&
                        "please Select any blood group"}
                    </span>
                  </label>
                </div>
              </div>
              {/* second div */}

              <div>
                <div className="form-control w-full">
                  <label className="label">
                    <span className="label-text">
                      জন্ম তারিখ <span className="text-red-400">*</span>
                    </span>
                  </label>

                  <DatePicker
                    selected={startDate}
                    className="input input-bordered w-full max-w-xs"
                    onChange={(date) => setStartDate(date ? date : new Date())}
                    showYearDropdown
                    scrollableMonthYearDropdown
                  />
                </div>
                <div className="form-control w-full">
                  <label className="label">
                    <span className="label-text">সম্পূর্ন ঠিকানা </span>
                  </label>
                  <input
                    {...register("address")}
                    type="text"
                    placeholder=""
                    className="input input-bordered w-full"
                  />
                </div>

                <div className="form-control w-full">
                  <label className="label">
                    <span className="label-text">
                      রক্তদানে সমর্থ? <span className="text-red-400">*</span>
                    </span>
                  </label>
                  <select
                    {...register("elegibale", { required: true })}
                    className="select select-bordered"
                  >
                    <option value={"Yes"}>Yes</option>
                    <option value={"No"}>No</option>
                  </select>
                </div>

                <div className="form-control w-full">
                  <label className="label">
                    <span className="label-text">Last Donation Date</span>
                  </label>
                  <input
                    {...register("lastDonationDate")}
                    type="text"
                    placeholder="MM/DD/YYYY"
                    className="input input-bordered w-full"
                  />
                </div>
                <div className="form-control w-full">
                  <label className="label">
                    <span className="label-text">
                      লিঙ্গ <span className="text-red-400">*</span>
                    </span>
                  </label>
                  <select
                    {...register("gender", { required: true })}
                    className="select select-bordered"
                  >
                    <option selected hidden>
                      select
                    </option>
                    <option value={"male"}>Male</option>
                    <option value={"female"}>Female</option>
                  </select>
                  <label className="label">
                    <span className="label-text-alt text-red-600">
                      {errors.gender?.type === "required" &&
                        "please Select any blood group"}
                    </span>
                  </label>
                </div>

                <div className="form-control w-full max-w-md ">
                  <label className="label">
                    <span className="label-text">কতবার রক্ত দিয়েছেন?</span>
                  </label>

                  <input
                    type="number"
                    {...register("donationCount")}
                    placeholder="Type Number"
                    className="input input-bordered w-full max-w-md"
                  />
                </div>
              </div>
            </div>
            <div>
              <input
                type={"submit"}
                value="রেজিষ্ট্রেশন"
                className="btn main-color w-full text-white mt-3"
              />
            </div>
          </form>
          <p className="text-red-500">
            {error && error.message.split("/")[1].slice(0, -2)}
          </p>
        </div>
      </div>
    </>
  );
};

export default Signup;
