import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import Loading from "../../Shared/Loading";

const EmailVerify = () => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  let navigate = useNavigate();
  let location = useLocation();
  let from = location.state?.from?.pathname || "/";
  /*  */
  /*   useEffect(() => { */
  /*     if (user || LoginUser?.email */ //) {
  /*       navigate("/"); */
  /*     } */
  /*   }, [user, LoginUser?.email]); */

  const handleEmailVerify = (data) => {};

  /* if (loading) {
       return <Loading></Loading>;
     } */

  return (
    <div className="hero min-h-screen bg-base-200">
      <div className="w-full max-w-md">
        <h1 className="text-5xl mb-8 font-bold ">Email Verification</h1>

        <form
          onSubmit={handleSubmit(handleEmailVerify)}
          className="card flex-shrink-0  shadow-2xl bg-base-100"
        >
          <div className="card-body">
            <div className="form-control">
              <input
                type={"email"}
                {...register("email", {
                  required: "Email Address is required",
                })}
                placeholder="type your email"
                className="input input-bordered"
              />
              <small className="text-red-500">{errors.email?.message}</small>
            </div>

            <div className="form-control mt-6">
              <button type="submit" className="btn btn-primary">
                Send Verification Link
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EmailVerify;
