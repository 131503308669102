import React from "react";
import { FaFacebookSquare } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";

const FamilyMember = ({ member, bgColor }) => {
  return (
    <div
      className="h-[218px] w-[241px] bg-slate-900 rounded-lg mx-auto md:mx-0 relative"
      style={{
        boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
        background: bgColor,
      }}
    >
      <div className="h-1/4"></div>
      <div className=" bg-white">
        <div className="text-center relative top-[-40px]">
          <img
            className="w-2/4 rounded-lg mx-auto mb-4 border-2 border-red-600 p-2"
            src={member?.image}
          />
          <p className="text-3xl text-black font-bold">{member?.fullName}</p>
          <p className="text-sm text-black font-bold">{member?.position}</p>
        </div>
      </div>
      <div className="family-member-social">
        <a
          href={member?.facebook}
          target="_blank"
          className="bg-white inline-block rounded-sm  mr-2"
        >
          <FaFacebookSquare className="text-2xl text-blue-800 hover:text-blue-600" />
        </a>
        <a
          href={`https://wa.me/${member?.whatsapp}`}
          className="bg-white inline-block rounded-sm  mr-2"
        >
          <FaWhatsapp className="text-2xl text-[#2fb1e8] hover:text-blue-600" />
        </a>
        <a
          href={member?.instagram}
          target="_blank"
          className="bg-white inline-block rounded-sm  mr-2"
        >
          <FaInstagramSquare className="text-2xl text-pink-600 hover:text-pink-400" />
        </a>
      </div>
    </div>
  );
};

export default FamilyMember;
