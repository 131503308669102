import React from "react";
import { Link, NavLink, Outlet } from "react-router-dom";
import { FaBullhorn } from "react-icons/fa";
import { FaCamera } from "react-icons/fa";
import { FaUserEdit } from "react-icons/fa";
import { FaUserCog } from "react-icons/fa";

const Dashboard = () => {
  const dashboardMenu = (
    <>
      <li className="shadow-sm rounded-md mb-2">
        <NavLink to={"/dashboard/"} className="flex items-center">
          <FaBullhorn className="" />
          <span>Notice</span>
        </NavLink>
      </li>
      <li className="shadow-sm rounded-md mb-2">
        <NavLink className="flex items-center" to={"/dashboard/edit-profile"}>
          <FaUserEdit className="" />
          Edit Profile
        </NavLink>
      </li>

      <li className="shadow-sm rounded-md mb-2">
        <NavLink className="flex items-center" to={"/dashboard/add-post"}>
          <FaCamera className="" />
          Add post
        </NavLink>
      </li>
      <li className="shadow-sm rounded-md mb-2">
        <NavLink className="flex items-center" to={"/dashboard/setting"}>
          <FaUserCog className="" />
          Settings
        </NavLink>
      </li>
    </>
  );

  return (
    <>
      <div className="drawer drawer-mobile">
        <input
          id="dashboard-side-menu"
          type="checkbox"
          className="drawer-toggle"
        />
        <div className="drawer-content px-3 md:px-0">
          <Outlet></Outlet>
          {/* <!-- Page content here --> */}
        </div>
        <div className="drawer-side ">
          <label for="dashboard-side-menu" className="drawer-overlay"></label>
          <ul className="menu p-4 overflow-y-auto w-80 bg-base-100 ">
            {dashboardMenu}
          </ul>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
