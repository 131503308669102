import React, { useEffect, useState } from "react";
import Avatar from "react-avatar-edit";

const Images = ({ setPreview, preview, handleUploadCropProfile }) => {
  const [src, setSrc] = useState(null);
  // const [preview, setPreview] = useState(null);

  const onClose = () => {
    setPreview(null);
  };
  const onCrop = (view) => {
    setPreview(view);
  };

  /*   const onBeforeFileLoad = (elem) => {
    if (elem.target.files[0].size > 716800) {
      alert("File is too big!");
      elem.target.value = "";
    }
  }; */

  return (
    <div className="flex gap-5">
      <input
        type="checkbox"
        id="profile-change-modal"
        className="modal-toggle"
      />
      <div className="modal ">
        <div className="modal-box relative ">
          <label
            htmlFor="profile-change-modal"
            className="btn btn-sm btn-circle absolute right-2 top-2"
          >
            ✕
          </label>
          <h3 className="text-2xl mb-3 font-bold">Upload profile photo</h3>
          {/* here modal */}

          <div className="">
            <div>
              <Avatar
                width={300}
                height={300}
                onCrop={onCrop}
                onClose={onClose}
                src={src}
                // onBeforeFileLoad={onBeforeFileLoad}
              />
            </div>
            {/*  <div className="w-[300px] ">
              <img src={preview} alt="Preview" className="w-1/2 mx-auto mt-5" />
            </div> */}
          </div>
          {/* close button */}
          <div>
            <button
              onClick={handleUploadCropProfile}
              className="btn main-color  mt-5 text-white "
            >
              Crop {" &"} Upload Photo
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Images;
