import React from "react";
import swal from "sweetalert";
import Loading from "../../Shared/Loading";

const DonnerMyPost = ({ donnnerPost, isLoading, refetch }) => {
  const handleDeletePost = (id) => {
    const deleteUrl = `https://rokto-bondon-server.vercel.app/delete-donner-post/?id=${id._id}&index=${id.index}`;
    swal({
      title: "Are you sure?",
      text: `Once deleted, you will not be able to recover " "`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        fetch(deleteUrl, {
          method: "DELETE",
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.matchedCount) {
              refetch();
            }
          });

        swal("Poof! Your imaginary file has been deleted!", {
          icon: "success",
        });
      } else {
        swal(`file is safe!`);
      }
    });
  };

  if (isLoading) return <Loading></Loading>;

  return (
    <div class="overflow-x-auto w-full">
      <table class="table w-full">
        <thead>
          <tr>
            <th>S/N</th>
            <th>Images</th>
            <th>action</th>
          </tr>
        </thead>
        <tbody>
          {donnnerPost &&
            donnnerPost?.images.map((post, index) => {
              return (
                <>
                  <tr>
                    <th>{index + 1}</th>
                    <td>
                      <div class="flex items-center space-x-3">
                        <div class="avatar">
                          <div class="mask mask-squircle w-12 h-12">
                            <img
                              src={post}
                              alt="Avatar Tailwind CSS Component"
                            />
                          </div>
                        </div>
                      </div>
                    </td>

                    <td>
                      <button
                        onClick={() =>
                          handleDeletePost({ index, _id: donnnerPost._id })
                        }
                        class="btn btn-warning btn-xs"
                      >
                        delete
                      </button>
                    </td>
                  </tr>
                </>
              );
            })}
        </tbody>

        <tfoot>
          <tr></tr>
        </tfoot>
      </table>
    </div>
  );
};

export default DonnerMyPost;
