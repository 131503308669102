import logo from "./logo.svg";
import "./App.css";
import Header from "./Shared/Header";
import Home from "./Pages/Home/Home";
import { Route, Routes } from "react-router-dom";
import Donner from "./Pages/Donner";
import BloodGroups from "./Pages/BloodGroup/BloodGroups";
import SerachResult from "./Pages/SearchResult/SerachResult";
import { useState } from "react";
import Registration from "./Pages/LoginAndRegistration/Registration";
import Login from "./Pages/LoginAndRegistration/Login";
import Signup from "./Pages/LoginAndRegistration/Signup";
import EmailVerify from "./Pages/LoginAndRegistration/EmailVerify";
import ForgotPassword from "./Pages/LoginAndRegistration/ForgotPassword";
import Dashboard from "./Pages/Dashboard/Dashboard";
import RequireAuth from "./Hooks/RequireAuth";
import EditProfile from "./Pages/Dashboard/EditProfile";
import Notice from "./Pages/Dashboard/Notice";
import Setting from "./Pages/Dashboard/Setting";
import AddPost from "./Pages/Dashboard/AddPost";
import Footer from "./Shared/Footer";
import MyProfile from "./Pages/Dashboard/MyProfile";
import AboutUs from "./Pages/AboutUs/AboutUs";
import OurActivites from "./Pages/OurActivites/OurActivites";
import SinglePageActivity from "./Pages/OurActivites/SinglePageActivity";
import Notfound from "./Shared/Notfound";
import Sohojogies from "./Pages/Sohojogi/Sohojogies";

function App() {
  const [donners, setDonners] = useState([]);

  return (
    <div className="">
      <Header></Header>

      <Routes>
        <Route path="/" element={<Home setDonners={setDonners}></Home>}></Route>
        <Route path="/donner/:id" element={<Donner></Donner>}></Route>
        <Route
          path="/bloodgroup/:group"
          element={<BloodGroups></BloodGroups>}
        ></Route>
        <Route
          path="/serachresult"
          element={
            <SerachResult
              setDonners={setDonners}
              donners={donners}
            ></SerachResult>
          }
        ></Route>
        {/* --------------------------------dashboard start  */}
        <Route
          path="/dashboard"
          element={
            <RequireAuth>
              <Dashboard></Dashboard>
            </RequireAuth>
          }
        >
          {/* dashboard child */}
          <Route
            path="edit-profile"
            element={<EditProfile></EditProfile>}
          ></Route>
          <Route
            path="myprofile/:email"
            element={<MyProfile></MyProfile>}
          ></Route>
          <Route path="setting" element={<Setting></Setting>}></Route>
          <Route index element={<Notice></Notice>}></Route>
          <Route path="add-post" element={<AddPost></AddPost>}></Route>
        </Route>
        {/* dashboard end */}

        <Route path="/login" element={<Login></Login>}></Route>
        <Route path="/signup" element={<Signup></Signup>}></Route>
        <Route path="/verify" element={<EmailVerify></EmailVerify>}></Route>
        <Route path="/about-us" element={<AboutUs></AboutUs>}></Route>
        <Route path="/all-social" element={<Sohojogies></Sohojogies>}></Route>
        <Route
          path="/our-activity"
          element={<OurActivites></OurActivites>}
        ></Route>
        <Route
          path="/single-page-activity/:idName"
          element={<SinglePageActivity></SinglePageActivity>}
        ></Route>
        <Route
          path="/forgot-password"
          element={<ForgotPassword></ForgotPassword>}
        ></Route>
        <Route path="*" element={<Notfound></Notfound>}></Route>
      </Routes>
      <Footer></Footer>
    </div>
  );
}

export default App;
