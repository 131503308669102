import React from "react";
import logo from "../Assets/footer-logo.png";
import FbMessage from "./FbMessage";
import { FaFacebookSquare } from "react-icons/fa";
import { FaPhoneSquareAlt } from "react-icons/fa";
import plystore from "../Assets/playstore.webp";
import appstore from "../Assets/app-store.png";

const Footer = () => {
  return (
    <footer className="mt-5">
      <div class=" md:px-16 px-5 py-8 bg-base-300 text-base-content grid md:grid-cols-3 grid-cols-1 gap-5 ">
        <div className="flex flex-col items-center">
          <div>
            <img src={logo} />
          </div>
          <p className="text-xl max-w-sm">
            একের রক্ত অন্যের জীবন <br />
            রক্তই হোক আত্মার বন্ধন।
          </p>
        </div>
        <div>
          <p className="text-xl max-w-sm  font-bold text-start mb-1">
            আমাদের কার্যক্রমঃ
          </p>
          <p>১. বিনামূল্যে রক্তের গ্রুপ নির্ণয় কর্মসূচি</p>
          <p>২. ফ্রি মেডিকেল ক্যাম্প</p>
          <p>৩. স্বেচ্ছায় রক্তদান নিয়ে সচেতনতা তৈরি</p>
          <p>৪. জাতীয় ও আন্তর্জাতিক দিবস পালন </p>
          <p>৫. বিভিন্ন সামাজিক কর্মকান্ড পালন</p>
          <p>৬. পথ শিশুদের বিদ্যালয়ে যাওয়ার ব্যবস্থা করা</p>
          <p>৭. গরীব অসহায় মানুষকে বিভিন্নভাবে সাহায্য করা</p>
          <p>৮. বাল্যবিবাহ ও মাদক রোধ করা নিয়ে সচেতনতা তৈরি</p>
        </div>
        <div>
          <div class="">
            <div>
              <br />
              <p className="text-xl max-w-sm font-bold text-center ">
                জরুরী রক্তের প্রয়োজনেঃ
                <br />
                <span className="flex items-center justify-center gap-1">
                  <FaPhoneSquareAlt /> <span>01902166881</span>
                </span>
              </p>
            </div>

            <br />
            <p className="text-xl max-w-sm font-bold  text-center">Follow Us</p>
            <div className="flex justify-center gap-2">
              <a
                className="mt-2 btn bg-blue-500 text-white flex items-center gap-1"
                href="https://www.facebook.com/bondhonroktodan"
                target={"_blank"}
              >
                <FaFacebookSquare className="mt-[-5px]" />
                <span>Facebook Page </span>
              </a>
              <a
                className="mt-2 btn bg-blue-500 text-white flex items-center gap-1"
                href="https://www.facebook.com/groups/bondhonroktodan"
                target={"_blank"}
              >
                <FaFacebookSquare className="mt-[-5px]" />
                <span>Facebook Group</span>
              </a>
            </div>
            <div className="flex justify-center gap-2">
              <a
                className="mt-2 btnflex items-center gap-1 w-[50%] "
                href="https://play.google.com/store/apps/details?id=com.pg.bondhonroktodan"
                target={"_blank"}
              >
                <img src={plystore} className="w-[150px] ml-auto" />
              </a>
              <a
                className="mt-2 flex items-center gap-1 w-[50%]"
                href="#"
                target={"_blank"}
              >
                <img src={appstore} className="w-[150px] mr-auto" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="grid md:grid-cols-3 grid-cols-1 gap-3 text-xs py-2 bg-white px-16 text-black">
        <p className="text-center md:text-start">
          all copyright reserved by bondhonroktodan.com
        </p>
        <p className="text-center md:text-end">
          যেকোনো প্রয়োজনে যোগাযোগ করুন{"  "}
          <a
            href="https://www.facebook.com/alamin.rimon2"
            className="font-bold hover:text-red-600 "
            target="_blank"
          >
            Alamin Miazi Rimon
          </a>
        </p>
        <p className="text-center md:text-end">
          Developed by{" "}
          <a
            href="https://www.facebook.com/profile.php?id=100007977125910"
            className="font-bold hover:text-red-600 "
            target="_blank"
          >
            Akash Shil
          </a>
        </p>
      </div>
      <FbMessage></FbMessage>
    </footer>
  );
};

export default Footer;
