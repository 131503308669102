import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import Heading from "../../Shared/Heading";
import { useAuthState } from "react-firebase-hooks/auth";
import auth from "../../firebase.init";
import { useQuery } from "react-query";
import swal from "sweetalert";
import Loading from "../../Shared/Loading";
import { format } from "date-fns";
import Images from "./ImageCrop/Images";
import { FaCloudUploadAlt } from "react-icons/fa";
import DasboardMenuHambarg from "../../Shared/DasboardMenuHambarg";

const EditProfile = () => {
  const [startDate, setStartDate] = useState(new Date());

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();
  const [user, loading] = useAuthState(auth);
  const [isLoading, setisLoading] = useState(true);
  // image crop privew state
  const [preview, setPreview] = useState(null);
  const [corpReload, setCorpReload] = useState(true);

  const url = `https://rokto-bondon-server.vercel.app/approvedRequest/${user?.email}`;

  const [profile, setProfile] = useState([]);
  // ----------------------------upload crop image function with base64 incoding-----------------------

  const handleUploadCropProfile = () => {
    if (preview) {
      const image = urlToFile(preview);
      // const image = data.image[0];
      const formData = new FormData();
      formData.append("image", image);

      fetch(imgUrl, {
        method: "POST",
        body: formData,
      })
        .then((res) => res.json())
        .then((result) => {
          if (result.success) {
            const photo = {
              img: result?.data?.url,
              request: true,
            };

            fetch(
              `https://rokto-bondon-server.vercel.app/approvedRequest/${user?.email}`,
              {
                method: "PUT",
                headers: {
                  "content-type": "application/json",
                },
                body: JSON.stringify(photo),
              }
            )
              .then((res) => res.json())
              .then((photo) => {
                if (photo?.modifiedCount) {
                  swal(
                    "Success",
                    "আপনার প্রোফাইল ছবি আপডেট হয়েছে, ২৪ ঘন্টার মধ্যে যাচাই করে পাবলিশ করা হবে",
                    "success"
                  );
                  reset();
                  setCorpReload(!corpReload);
                }
              });
          }
        });
    }
  };

  const urlToFile = (url) => {
    let arr = url.split(",");
    let mime = arr[0].match(/:(.*?);/)[1];
    let data = arr[1];
    let dataStr = atob(data);
    let n = dataStr.length;
    let DataArr = new Uint8Array(n);
    while (n--) {
      DataArr[n] = dataStr.charCodeAt(n);
    }
    let file = new File([DataArr], "File.jpg", { type: mime });
    return file;
  };

  /*   const {
    data: profile,
    isLoading,
    refetch,
  } = useQuery("bannerSlider", () => fetch(url).then((res) => res.json())); */

  useEffect(() => {
    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        setProfile(data);
        // setStartDate(data[0].birthday);
        if (data[0]?.birthday) {
          setStartDate(new Date(data[0]?.birthday));
          return setisLoading(false);
        }
        setStartDate(new Date());
        setisLoading(false);
      });
  }, [corpReload]);

  const date = format(startDate, "PP");

  const imgUrl = `https://api.imgbb.com/1/upload?key=${process.env.REACT_APP_imageKey}`;

  const HandleUpdateProfilePhoto = (data) => {
    /* const image = data.image[0];
    const formData = new FormData();
    formData.append("image", image);

    fetch(imgUrl, {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.success) {
          const photo = {
            img: result?.data?.url,
            request: true,
          };

          fetch(
            `https://rokto-bondon-server.vercel.app/approvedRequest/${user?.email}`,
            {
              method: "PUT",
              headers: {
                "content-type": "application/json",
              },
              body: JSON.stringify(photo),
            }
          )
            .then((res) => res.json())
            .then((photo) => {
              if (photo?.modifiedCount) {
                swal("Success", "", "success");
                reset();
              }
            });
        }
      }); */
  };

  const HandleUpdateProfile = (data) => {
    const updateDonner = {
      fullName: data.fullName,
      bloodGroup: data.bloodGroup,
      distric: data.distric,
      address: data.address,
      donationCount: data.donationCount,
      email: user?.email,
      phone: data.phone,
      fbID: data.fbID,
      birthday: date,
      elegibale: data.elegibale,
      gender: data.gender,
      request: true,
      showPhone: true,
      lastDonationDate: data.lastDonationDate,
    };

    fetch(
      `https://rokto-bondon-server.vercel.app/approvedRequest/${user?.email}`,
      {
        method: "PUT",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(updateDonner),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data?.upsertedId || !data?.modifiedCount || data.acknowledged) {
          swal(
            "success",
            "আপনার প্রোফাইল ইনফরমেশন আপডেট হয়েছে, ২৪ ঘন্টার মধ্যে যাচাই করে পাবলিশ করা হবে",
            "success"
          );
        }
      });
  };

  if (isLoading) {
    return <Loading></Loading>;
  }
  return (
    <div className="pb-16">
      <div>
        <div className=" w-full md:w-3/4 max-w-5xl md:px-0 px-2">
          <Heading>
            <div className="flex justify-between items-center">
              <span>Edit Profile</span>
              <DasboardMenuHambarg></DasboardMenuHambarg>
            </div>
          </Heading>
          <form onSubmit={handleSubmit(HandleUpdateProfile)}>
            <div className="grid md:grid-cols-2 gap-5">
              <div>
                <div className="form-control w-full max-w-sm">
                  <label className="label">
                    <span className="label-text">
                      Full Name <span className="text-red-400">*</span>
                    </span>
                  </label>
                  <input
                    defaultValue={profile[0]?.fullName}
                    {...register("fullName", { required: true })}
                    type="text"
                    placeholder="Name"
                    className="input input-bordered w-full max-w-sm"
                  />
                  <label className="label">
                    <span className="label-text-alt text-red-600">
                      {errors.fullName?.type === "required" &&
                        "name is required Ex: Akash Shil"}
                    </span>
                  </label>
                </div>

                <div className="form-control w-full max-w-sm">
                  <label className="label">
                    <span className="label-text">
                      Blood Group <span className="text-red-400">*</span>{" "}
                    </span>
                  </label>
                  <select
                    defaultValue={profile[0]?.bloodGroup}
                    {...register("bloodGroup", { required: true })}
                    className="select select-bordered"
                  >
                    <option selected hidden>
                      select
                    </option>
                    <option value={"A+"}>A+</option>
                    <option value={"A-"}>A-</option>
                    <option value={"B+"}>B+</option>
                    <option value={"B-"}>B-</option>
                    <option value={"AB+"}>AB+</option>
                    <option value={"AB-"}>AB-</option>
                    <option value={"O+"}>O+</option>
                    <option value={"O-"}>O-</option>
                  </select>
                  <label className="label">
                    <span className="label-text-alt text-red-600">
                      {errors.bloodGroup?.type === "required" &&
                        "please Select any blood group"}
                    </span>
                  </label>
                </div>

                <div className="form-control w-full max-w-sm">
                  <label className="label">
                    <span className="label-text">Full Address </span>
                  </label>
                  <input
                    defaultValue={profile[0]?.address}
                    {...register("address")}
                    type="text"
                    placeholder=""
                    className="input input-bordered w-full max-w-sm"
                  />
                </div>

                <div className="form-control w-full max-w-sm">
                  <label className="label">
                    <span className="label-text">
                      Distric <span className="text-red-400">*</span>
                    </span>
                  </label>
                  <select
                    defaultValue={profile[0]?.distric}
                    {...register("distric", { required: true })}
                    className="select select-bordered"
                  >
                    <option selected hidden>
                      select
                    </option>
                    <option value={"Dhaka"}>Dhaka</option>
                    <option value={"Faridpur"}>Faridpur</option>
                    <option value={"Gazipur"}>Gazipur</option>
                    <option value={"Gopalganj"}>Gopalganj</option>
                    <option value={"Jamalpur"}>Jamalpur</option>
                    <option value={"Kishoreganj"}>Kishoreganj</option>
                    <option value={"Madaripur"}>Madaripur</option>
                    <option value={"Manikganj"}>Manikganj</option>
                    <option value={"Munshiganj"}>Munshiganj</option>
                    <option value={"Mymensingh"}>Mymensingh</option>
                    <option value={"Narayanganj"}>Narayanganj</option>
                    <option value={"Narsingdi"}>Narsingdi</option>
                    <option value={"Netrokona"}>Netrokona</option>
                    <option value={"Rajbari"}>Rajbari</option>
                    <option value={"Shariatpur"}>Shariatpur</option>
                    <option value={"Sherpur"}>Sherpur</option>
                    <option value={"Tangail"}>Tangail</option>
                    <option value={"Bogra"}>Bogra</option>
                    <option value={"Joypurhat"}>Joypurhat</option>
                    <option value={"Naogaon"}>Naogaon</option>
                    <option value={"Natore"}>Natore</option>
                    <option value={"Nawabganj"}>Nawabganj</option>
                    <option value={"Pabna"}>Pabna</option>
                    <option value={"Rajshahi"}>Rajshahi</option>
                    <option value={"Sirajgonj"}>Sirajgonj</option>
                    <option value={"Dinajpur"}>Dinajpur</option>
                    <option value={"Gaibandha"}>Gaibandha</option>
                    <option value={"Kurigram"}>Kurigram</option>
                    <option value={"Lalmonirhat"}>Lalmonirhat</option>
                    <option value={"Nilphamari"}>Nilphamari</option>
                    <option value={"Panchagarh"}>Panchagarh</option>
                    <option value={"Rangpur"}>Rangpur</option>
                    <option value={"Thakurgaon"}>Thakurgaon</option>
                    <option value={"Barguna"}>Barguna</option>
                    <option value={"Barisal"}>Barisal</option>
                    <option value={"Bhola"}>Bhola</option>
                    <option value={"Jhalokati"}>Jhalokati</option>
                    <option value={"Patuakhali"}>Patuakhali</option>
                    <option value={"Pirojpur"}>Pirojpur</option>
                    <option value={"Bandarban"}>Bandarban</option>
                    <option value={"Brahmanbaria"}>Brahmanbaria</option>
                    <option value={"Chandpur"}>Chandpur</option>
                    <option value={"Chittagong"}>Chittagong</option>
                    <option value={"Comilla"}>Comilla</option>
                    <option value={"Cox"}>Cox</option>''s Bazar
                    <option value={"Feni"}>Feni</option>
                    <option value={"Khagrachari"}>Khagrachari</option>
                    <option value={"Lakshmipur"}>Lakshmipur</option>
                    <option value={"Noakhali"}>Noakhali</option>
                    <option value={"Rangamati"}>Rangamati</option>
                    <option value={"Habiganj"}>Habiganj</option>
                    <option value={"Maulvibazar"}>Maulvibazar</option>
                    <option value={"Sunamganj"}>Sunamganj</option>
                    <option value={"Sylhet"}>Sylhet</option>
                    <option value={"Bagerhat"}>Bagerhat</option>
                    <option value={"Chuadanga"}>Chuadanga</option>
                    <option value={"Jessore"}>Jessore</option>
                    <option value={"Jhenaidah"}>Jhenaidah</option>
                    <option value={"Khulna"}>Khulna</option>
                    <option value={"Kushtia"}>Kushtia</option>
                    <option value={"Magura"}>Magura</option>
                    <option value={"Meherpur"}>Meherpur</option>
                    <option value={"Narail"}>Narail</option>
                    <option value={"Satkhira"}>Satkhira</option>
                  </select>
                  <label className="label">
                    <span className="label-text-alt text-red-600">
                      {errors.distric?.type === "required" &&
                        "please Select any blood group"}
                    </span>
                  </label>
                </div>

                <div className="form-control w-full max-w-sm">
                  <label className="label">
                    <span className="label-text">
                      Elegibale <span className="text-red-400">*</span>
                    </span>
                  </label>
                  <select
                    defaultValue={profile[0]?.elegibale}
                    {...register("elegibale", { required: true })}
                    className="select select-bordered"
                  >
                    <option value={"Yes"}>Yes</option>
                    <option value={"No"}>No</option>
                  </select>
                  <label className="label">
                    <span className="label-text-alt text-red-600">
                      {errors.elegibale?.type === "required" &&
                        "please Select yes/no"}
                    </span>
                  </label>
                </div>

                <div className="form-control w-full max-w-sm">
                  <label className="label">
                    <span className="label-text">
                      Last Donation Date <span className="text-red-400">*</span>
                    </span>
                  </label>
                  <input
                    defaultValue={profile[0]?.lastDonationDate}
                    {...register("lastDonationDate")}
                    type="text"
                    placeholder="MM/DD/YYYY"
                    className="input input-bordered w-full max-w-sm"
                  />
                </div>
              </div>
              {/* second div */}

              <div>
                <div className="form-control w-full max-w-sm">
                  <label className="label">
                    <span className="label-text">
                      Gender <span className="text-red-400">*</span>
                    </span>
                  </label>
                  <select
                    defaultValue={profile[0]?.gender}
                    {...register("gender", { required: true })}
                    className="select select-bordered"
                  >
                    <option selected hidden>
                      select
                    </option>
                    <option value={"male"}>Male</option>
                    <option value={"female"}>Female</option>
                  </select>
                  <label className="label">
                    <span className="label-text-alt text-red-600">
                      {errors.gender?.type === "required" &&
                        "please Select any blood group"}
                    </span>
                  </label>
                </div>
                <div className="form-control w-full max-w-sm">
                  <label className="label">
                    <span className="label-text">Birthday Date: </span>
                  </label>

                  <DatePicker
                    selected={startDate}
                    className="input input-bordered w-full max-w-xs"
                    onChange={(date) => setStartDate(date ? date : new Date())}
                    showYearDropdown
                    scrollableMonthYearDropdown
                  />
                </div>

                <div className="form-control w-full max-w-sm mt-3">
                  <label className="label">
                    <span className="label-text">
                      Phone Number <span className="text-red-400">*</span>
                    </span>
                  </label>

                  <input
                    defaultValue={profile[0]?.phone}
                    {...register("phone", { required: true })}
                    type="text"
                    placeholder="Type here"
                    className="input input-bordered w-full max-w-sm"
                  />
                </div>

                <div className="form-control w-full max-w-sm  mt-3">
                  <label className="label">
                    <span className="label-text">Email Address</span>
                  </label>

                  <input
                    defaultValue={user?.email}
                    disabled
                    type="email"
                    {...register("email")}
                    placeholder="Type here"
                    className="input input-bordered w-full max-w-sm"
                  />
                </div>

                <div className="form-control w-full max-w-md  mt-3">
                  <label className="label">
                    <span className="label-text">কতবার রক্ত দিয়েছেন?</span>
                  </label>

                  <input
                    defaultValue={profile[0]?.donationCount}
                    type="number"
                    {...register("donationCount")}
                    placeholder="Type Number"
                    className="input input-bordered w-full max-w-md"
                  />
                </div>
                <div className="form-control w-full max-w-md  mt-3">
                  <label className="label">
                    <span className="label-text">Facebook Id profile URL</span>
                  </label>

                  <input
                    defaultValue={profile[0]?.fbID}
                    type="text"
                    {...register("fbID")}
                    placeholder="please copy/paste fb url"
                    className="input input-bordered w-full max-w-md"
                  />
                </div>
              </div>
            </div>
            <div>
              <input
                type={"submit"}
                value="Update profile"
                className="btn main-color w-full text-white mt-3"
              />
            </div>
          </form>

          <div className="mt-5">
            {/* for image update form */}
            <h3 className="font-bold text-lg">Update Profile Photo</h3>
            <span className="divider my-1"></span>
            {/* modal image  */}
            <div>
              <div className="w-72 p-3 border-2 mb-3 border-[#9E0C00]">
                <img
                  src={
                    !profile[0]?.img
                      ? "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909__340.png"
                      : profile[0]?.img
                  }
                  alt="Preview"
                  className="w-2/3 mx-auto mt-5"
                />
              </div>

              <label
                htmlFor="profile-change-modal"
                className="btn modal-button main-color text-white  "
              >
                <FaCloudUploadAlt className="text-3xl mr-1" />{" "}
                <span className="mt-2">Choose File...</span>
              </label>

              <Images
                setPreview={setPreview}
                handleUploadCropProfile={handleUploadCropProfile}
              ></Images>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditProfile;
