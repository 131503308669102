import React from "react";

const HomeHeading = ({ children }) => {
  return (
    <div className="my-8">
      <div className="text-center divider">
        <h3 className="text-xl md:text-5xl home-heading-font">{children}</h3>
      </div>
    </div>
  );
};

export default HomeHeading;
