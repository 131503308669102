import React from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import auth from "../../firebase.init";
import { useSendPasswordResetEmail } from "react-firebase-hooks/auth";
import swal from "sweetalert";
import Loading from "../../Shared/Loading";

const ForgotPassword = () => {
  const [sendPasswordResetEmail, sending, error] =
    useSendPasswordResetEmail(auth);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const handleResetPassword = async (data) => {
    const email = data.email;
    await sendPasswordResetEmail(email);
    if (!error) {
      swal("", "", "success");
    }
  };

  if (sending) {
    return <Loading></Loading>;
  }

  return (
    <div className="hero min-h-screen bg-base-200">
      <div className="w-full max-w-md">
        <h1 className="text-5xl mb-8 font-bold ">Password Reset</h1>

        <form
          onSubmit={handleSubmit(handleResetPassword)}
          className="card flex-shrink-0  shadow-2xl bg-base-100"
        >
          <div className="card-body">
            <div className="form-control">
              <input
                type={"email"}
                {...register("email", {
                  required: "Email Address is required",
                })}
                placeholder="type your email"
                className="input input-bordered"
              />
              <small className="text-red-500">{errors.email?.message}</small>
            </div>

            <div className="form-control mt-6">
              <button type="submit" className="btn btn-primary">
                Reset Password
              </button>
            </div>
            <div className="">
              {error && (
                <p className="text-red-500">
                  Error: {error.message.split("/")[1].slice(0, -2)}
                </p>
              )}
              <label className="label">
                <Link to={"/login"} className="label-text-alt link link-hover">
                  Back Login
                </Link>
              </label>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;
