import React from "react";
import HomeHeading from "../../Shared/HomeHeading";

const RoktoKnowladgeSection = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-5 my-8 md:px-16 px-2">
      <div className="p-3 border-red-100 border-2">
        <h3 className="text-3xl  mb-4">কেন রক্তদান করবেন?</h3>
        <div className=" " id="solaiman-font">
          <p>
            ১. প্রথম এবং প্রধান কারণ, আপনার দানকৃত রক্ত একজন মানুষের জীবন
            বাঁচাবে। রক্তদানের জন্য এর থেকে বড় কারণ আর কি হতে পারে !
          </p>
          <p>২. হয়তো একদিন আপনার নিজের প্রয়োজনে/বিপদে অন্য কেউ এগিয়ে আসবে।</p>
          <p>৩. নিয়মিত রক্তদানে হৃদরোগ ও হার্ট অ্যাটাকের ঝুঁকি অনেক কম।</p>
          <p>
            ৪. নিয়মিত স্বেচ্ছায় রক্তদানের মাধ্যমে বিনা খরচে জানা যায় নিজের শরীরে
            বড় কোনো রোগ আছে কিনা। যেমন : হেপাটাইটিস-বি, হেপাটাইটিস-সি, সিফিলিস,
            এইচআইভি (এইডস) ইত্যাদি।
          </p>
          <p>৫. দেহের রোগ প্রতিরোধ ক্ষমতা অনেকগুন বেড়ে যায়।</p>
          <p>
            ৬. রক্তদান ধর্মীয় দিক থেকে অত্যন্ত পুণ্যের বা সওয়াবের কাজ। পবিত্র
            কোরআনের সূরা মায়েদার ৩২ নং আয়াতে আছে, ‘একজন মানুষের জীবন বাঁচানো
            সমগ্র মানব জাতির জীবন বাঁচানোর মতো মহান কাজ।
          </p>

          <p>
            ৭. নিজের মাঝে একধরনের আত্মতৃপ্তি উপলব্ধি করতে পারবেন । "আমাদের ছোট
            পরিসরের এই জীবনে কিছু একটা করলাম" এই অনুভুতি আপনার মনে জাগ্রত হবে এই
            ব্যাপারে নিশ্চিত করছি
          </p>
        </div>
      </div>
      <div className="p-3 border-red-100 border-2">
        <h3 className="text-3xl mb-4 ">কারা রক্তদান করতে পারবেন?</h3>
        <div className=" " id="solaiman-font">
          <p>
            ১) ১৮ বছর থেকে ৬০ বছরের যেকোনো সুস্থদেহের মানুষ রক্ত দান করতে
            পারবেন।
          </p>
          <p>২) শারীরিক এবং মানসিক ভাবে সুস্থ নিরোগ ব্যক্তি রক্ত দিতে পারবেন</p>
          <p> ৩) আপনার ওজন অবশ্যই ৫০ কিলোগ্রাম কিংবা তার বেশি হতে হবে।</p>
          <p> ৪) চার (৪) মাস অন্তর অন্তর রক্তদান করা যায়।</p>
          <p>
            ৫) রক্তে হিমোগ্লোবিনের পরিমাণ, রক্তচাপ ও শরীরের তাপমাত্রা স্বাভাবিক
            থাকতে হবে।
          </p>
          <p>
            ৬) শ্বাস-প্রশ্বাসজনিত রোগ এ্যাজমা, হাপানি যাদের আছে তারা রক্ত দিতে
            পারবেন না।
          </p>
          <p>
            ৭) রক্তবাহিত জটিল রোগ যেমন-ম্যালেরিয়া, সিফিলিস, গনোরিয়া, হেপাটাইটিস
            , এইডস, চর্মরোগ, হৃদরোগ, ডায়াবেটিস, টাইফয়েড এবং বাতজ্বর না থাকলে।
          </p>
          <p> ৮) আপনাকে চর্মরোগ মুক্ত হতে হবে।</p>
          <p> ৯) মহিলাদের মধ্যে যারা গর্ভবতী নন এবং যাদের মাসিক চলছে না।</p>
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default RoktoKnowladgeSection;
