import React from "react";
import { Link } from "react-router-dom";

const Activity = ({ post }) => {
  return (
    <div className="card w-full md:w-96 bg-slate-200 shadow-xl">
      <figure className="">
        <img
          src={post?.image}
          alt="Shoes"
          className="w-full h-42 object-cover"
        />
      </figure>
      <div className="card-body">
        <h2 className="card-title text-primary">
          {post?.title.slice(0, 40)}
          {post?.title?.length > 10 ? "..." : ""}
        </h2>
        <p className="text-slate-700 text-justify">
          {post?.expect.slice(0, 130)}
          {post?.expect?.length > 130 ? "..." : ""}
        </p>
        <div className="card-actions justify-end">
          <Link
            to={`/single-page-activity/${post._id}`}
            className="btn bg-slate-900 "
          >
            বিস্তারিত...
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Activity;
