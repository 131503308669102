import React from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import auth from "../../firebase.init";
import { useForm } from "react-hook-form";

import swal from "sweetalert";
import Loading from "../../Shared/Loading";

const AddPostSection = ({ refetch }) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();
  const [user, loading] = useAuthState(auth);
  // console.log(user);

  const imgUrl = `https://api.imgbb.com/1/upload?key=${process.env.REACT_APP_imageKey}`;
  const postUrl = `https://rokto-bondon-server.vercel.app/addPost-donner/${user?.email}`;

  const handleAddPost = (data) => {
    const image = data.image[0];
    const formData = new FormData();
    formData.append("image", image);

    fetch(imgUrl, {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.success) {
          const post = {
            email: user?.email,
            images: result.data.url,
          };

          console.log(postUrl);
          fetch(postUrl, {
            method: "PUT",
            headers: {
              "content-type": "application/json",
            },
            body: JSON.stringify(post),
          })
            .then((res) => res.json())
            .then((posts) => {
              if (posts?.acknowledged) {
                swal("Post Successfull", "", "success");
                refetch();
              }
            });
        }
      });
  };

  return (
    <div className="mt-5">
      {/* for image update form */}

      <form onSubmit={handleSubmit(handleAddPost)}>
        <div className="form-control w-full max-w-md">
          <label className="label">
            <span className="label-text">
              Upload photo
              <small className="font-bold text-green-500">
                (Photo size less then 200kb)
              </small>
            </span>
          </label>

          <input
            {...register("image")}
            type="file"
            className="input input-bordered mb-3 w-full "
          />
        </div>
        <input
          type={"submit"}
          value="Add Post"
          className="btn main-color  text-white"
        />
      </form>
    </div>
  );
};

export default AddPostSection;
