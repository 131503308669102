import React from "react";
import MessengerCustomerChat from "react-messenger-customer-chat/lib/MessengerCustomerChat";

const FbMessage = () => {
  return (
    <div>
      <MessengerCustomerChat
        pageId="824937271030828"
        appId="3153861514832295"
      />
    </div>
  );
};

export default FbMessage;
