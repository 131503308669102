import React from "react";
import HomeHeading from "../../Shared/HomeHeading";
import "./BirthdayBox.css";
import birthdyImage from "../../Assets/Birthday.jpg";

import { Swiper, SwiperSlide } from "swiper/react";
import nobirthday from "../../Assets/no-birthday.jpg";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import Loading from "../../Shared/Loading";
// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";
import { useQuery } from "react-query";

import isponsor from "../../Assets/sponsor.jpg";

const BirthdayBox = () => {
  const {
    data: birthday,
    isLoading,
    reFetch,
  } = useQuery("todayBirthdayData", () =>
    fetch("https://rokto-bondon-server.vercel.app/birthday").then((res) =>
      res.json()
    )
  );

  if (isLoading) {
    return <Loading></Loading>;
  }

  return (
    <div className="md:px-16 px-2 my-1 md:my-5">
      <div className="mt-2 grid md:grid-cols-2 grid-cols-1 gap-5 md:gap-0 ">
        <div className=" md:mr-5 mr-0 birthday-box-shadow flex justify-center items-center ">
          <img src={isponsor} className="h-full" />
        </div>
        <div>
          <>
            <Swiper
              spaceBetween={30}
              centeredSlides={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              pagination={{
                clickable: true,
              }}
              navigation={true}
              modules={[Autoplay, Pagination, Navigation]}
              className="mySwiper "
              style={{ height: "330px" }}
             
            >
              {birthday &&
                birthday.map((birth) => {
                  return (
                    <>
                      <SwiperSlide>
                        <div className=" birthday-box-shadow">
                          <div className="">
                            <img src={birthdyImage} />
                          </div>
                          <div className="birthday-info md:mt-0 mt-5">
                            <div class="avatar">
                              <div class="lg:w-48 md:w-32 w-20 rounded-full ring ring-white ring-offset-base-100 ring-offset-2 my-5">
                                <img src={birth?.img} />
                              </div>
                            </div>
                            <div className="md:w-48 w-32 p-2 md:ml-0 ml-[-25px] md:p-4 border-4 border-red-600 bg-white">
                              <p className="md:text-xl text-sm text-black text-center">
                                {birth?.fullName}
                              </p>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                    </>
                  );
                })}
              {birthday?.length == 0 && (
                <SwiperSlide>
                  <div className=" birthday-box-shadow">
                    <div className="">
                      <img src={nobirthday} />
                    </div>
                  </div>
                </SwiperSlide>
              )}
            </Swiper>
          </>
        </div>
      </div>
    </div>
  );
};

export default BirthdayBox;
