import React from "react";

const SohojogiCard = ({ sohojogi }) => {
  return (
    <div className="shadow-lg bg-[#0F172A] flex items-center  p-4 rounded-lg">
      <div className="w-[120px] bg-white h-full flex items-center">
        <img src={sohojogi.image} className="w-[120px]  bg-white" />
      </div>
      <div className="w-3/4 text-white text-center">
        <h2 className="md:text-3xl text-xl font-semibold">
          {sohojogi.fullName}
        </h2>
        <p className="text-sm md:text-xl">স্থাপিত: {sohojogi.established}</p>
        <p className="text-sm md:text-xl">{sohojogi.Adress}</p>
        <p className="text-sm md:text-xl">মোবাইল: {sohojogi.mobile}</p>
      </div>
    </div>
  );
};

export default SohojogiCard;
