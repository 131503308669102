import React from "react";
import "./Badges.css";

const Badges = () => {
  return (
    <div className="badage-wrapper">
      <div className="profile-badge">
        <i className="badge-left"></i>
        <i className="badge-right"></i>
        দূর্দান্ত সৈনিক
      </div>
    </div>
  );
};

export default Badges;
