import React from "react";
import Header from "../../Shared/Header";
import BirthdayBox from "../BirthdayBox/BirthdayBox";
import Banner from "./Banner/Banner";
import BestBloodDonner from "./BestBloodDonner";
import OurCommite from "./OurCommite/OurCommite";
import RoktoKnowladgeSection from "./RoktoKnowladgeSection";
import Serach from "./Serach";

const Home = ({ setDonners }) => {
  return (
    <>
      <Banner></Banner>
      <Serach setDonners={setDonners}></Serach>
      <BestBloodDonner></BestBloodDonner>
      <RoktoKnowladgeSection></RoktoKnowladgeSection>
      <BirthdayBox></BirthdayBox>
      <OurCommite></OurCommite>
    </>
  );
};

export default Home;
