import { async } from "@firebase/util";
import { id } from "date-fns/locale";
import React, { useState } from "react";
import { useAuthState, useUpdatePassword } from "react-firebase-hooks/auth";
import { useQuery } from "react-query";
import swal from "sweetalert";
import auth from "../../firebase.init";
import DasboardMenuHambarg from "../../Shared/DasboardMenuHambarg";
import Heading from "../../Shared/Heading";
import Loading from "../../Shared/Loading";

const Setting = () => {
  const [user] = useAuthState(auth);
  const [ErrorChange, setErrorChange] = useState("");
  const [updatePassword, updating, error] = useUpdatePassword(auth);
  // get elegiable
  const phoneUrl = `https://rokto-bondon-server.vercel.app/donner-profile-email/${user?.email}`;
  const {
    data: profile,
    isLoading,
    refetch,
  } = useQuery("settingProfiles", () =>
    fetch(phoneUrl).then((res) => res.json())
  );

  const handleHidePhone = () => {
    const url = `https://rokto-bondon-server.vercel.app/settingPhoneNumber/${user?.email}`;
    fetch(url, {
      method: "PUT",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({ showPhone: !profile?.showPhone }),
    })
      .then((res) => res.json())
      .then((data) => {
        fetch(
          `https://rokto-bondon-server.vercel.app/approvedRequest/${user?.email}`,
          {
            method: "PUT",
            headers: {
              "content-type": "application/json",
            },
            body: JSON.stringify({ showPhone: !profile?.showPhone }),
          }
        )
          .then((res) => res.json())
          .then((data) => {
            if (data?.upsertedId || !data?.modifiedCount || data.acknowledged) {
              refetch();
            }
          });

        refetch();
      });
  };

  const handleChangePassword = async (e) => {
    e.preventDefault();
    const newPassword = e.target.password.value;
    const confirmPassword = e.target.confirmPassword.value;
    if (newPassword !== confirmPassword) {
      return setErrorChange("password dont matched.try again.. !!");
    }
    setErrorChange("");
    if (!error) {
      await updatePassword(newPassword);
      swal("Updated password", " ", "success");
    }
  };

  if (updating) {
    return <Loading></Loading>;
  }
  return (
    <div>
      <Heading>
        <div className="flex justify-between items-center">
          <span>Settings</span>
          <DasboardMenuHambarg></DasboardMenuHambarg>
        </div>
      </Heading>

      <div>
        <span>বর্তমানে আপনার মোবাইল নাম্বারটির অবস্থাঃ</span>
        <br></br>
        {profile?.phone ? profile.phone : "Not available"}
        <button
          onClick={handleHidePhone}
          className="btn  main-color border-0 ml-2"
        >
          {!profile?.showPhone ? "Only Me" : "Public"}
        </button>
      </div>

      <div className="md:w-1/4 w-full">
        <Heading>Change Password</Heading>
        <form onSubmit={handleChangePassword}>
          <div>
            <label>New Password:</label>
            <input
              className="input input-bordered w-full mt-2"
              type={"password"}
              name="password"
              required
              placeholder="type Here"
            />
          </div>
          <div className="my-5">
            <label>Confirm Password:</label>
            <input
              className="input input-bordered w-full mt-2"
              type={"password"}
              required
              name="confirmPassword"
              placeholder="type Here"
            />
          </div>
          <input
            className="btn main-color border-0 w-full mt-2"
            type={"submit"}
            name="submit"
            value={"Change Password"}
            placeholder="type Here"
          />
        </form>
        <p className="text-red-500 my-5">
          {ErrorChange} {error?.message && error?.message}
        </p>
      </div>
    </div>
  );
};

export default Setting;
