import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useQuery } from "react-query";
import HomeHeading from "../../Shared/HomeHeading";
import Loading from "../../Shared/Loading";
import SohojogiCard from "./SohojogiCard";

const Sohojogies = () => {
  let url = `https://rokto-bondon-server.vercel.app/all-sohojogi`;
  const {
    data: sohojogies,
    isLoading,
    refetch,
  } = useQuery("memberofFamily", () => fetch(url).then((res) => res.json()));

  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);

  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 15;

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(sohojogies?.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(sohojogies?.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, sohojogies]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % sohojogies?.length;
    setItemOffset(newOffset);
  };

  if (isLoading) {
    return <Loading></Loading>;
  }

  return (
    <div className="md:px-16 px-3 md:pb-12 pb-5">
      <HomeHeading>সহযোগী সংগঠন</HomeHeading>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-5">
        {currentItems?.map((sohojogi) => (
          <SohojogiCard sohojogi={sohojogi} key={sohojogi?._id} />
        ))}
      </div>
      {/* paginate */}
      <div>
        <ReactPaginate
          breakLabel="..."
          nextLabel=">>"
          onPageChange={handlePageClick}
          pageRangeDisplayed={1}
          marginPagesDisplayed={1}
          pageCount={pageCount}
          previousLabel="<<"
          renderOnZeroPageCount={null}
          containerClassName="btn-group pagination"
          pageLinkClassName="btn btn-sm"
          previousLinkClassName="btn-sm btn"
          nextLinkClassName="btn btn-sm"
          activeClassName="pagination-active"
        />
      </div>
    </div>
  );
};

export default Sohojogies;
