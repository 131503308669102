import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useParams, Link } from "react-router-dom";
import Loading from "../../Shared/Loading";
import DonnerProfile from "../DonnerProfile";

const MyProfile = () => {
  const { email } = useParams();

  const [donner, setDonner] = useState({});

  const url = `https://rokto-bondon-server.vercel.app/my-profile/${email}`;

  useEffect(() => {
    fetch(url)
      .then((res) => res.json())
      .then((data) => setDonner(data));
  }, [email]);

  /*  const {
  data: myProfile,
    isLoading,
    reFetch,
  } = useQuery("myProfileDataforMy", () =>
    fetch(url).then((res) => res.json())
  ); */
  // console.log(myProfile);
  /*   if (isLoading) {
    return <Loading></Loading>;
  } */
  return <DonnerProfile donner={donner}></DonnerProfile>;
};

export default MyProfile;
