import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import LoadingCercle from "../../Shared/AllLoading/LoadingCercle";
import BestSinglneDonner from "../Home/BestSinglneDonner";

const BloodGroups = () => {
  const { group } = useParams();
  const [donners, setDonners] = useState([]);
  const [loading, setLoading] = useState(false);

  const url = `https://rokto-bondon-server.vercel.app/bloodgroup/${group.toUpperCase()}`;

  useEffect(() => {
    setLoading(true);
    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        setDonners(data);
        setLoading(false);
      })
      .finally(() => setLoading(false));
  }, [url]);

  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);

  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 12;

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(donners?.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(donners?.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, donners]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % donners?.length;
    setItemOffset(newOffset);
  };

  if (loading) {
    return <LoadingCercle />;
  }

  return (
    <div className="mt-5 mb-60 px-1 md:px-12">
      <div className="grid md:grid-cols-3 lg:grid-cols-6 grid-cols-2 gap-4">
        {currentItems?.map((donner) => (
          <BestSinglneDonner
            key={donner._id}
            donner={donner}
          ></BestSinglneDonner>
        ))}
      </div>
      {/* paginate */}
      <div>
        <ReactPaginate
          breakLabel="..."
          nextLabel=">>"
          onPageChange={handlePageClick}
          pageRangeDisplayed={1}
          marginPagesDisplayed={1}
          pageCount={pageCount}
          previousLabel="<<"
          renderOnZeroPageCount={null}
          containerClassName="btn-group pagination"
          pageLinkClassName="btn btn-sm"
          previousLinkClassName="btn-sm btn"
          nextLinkClassName="btn btn-sm"
          activeClassName="pagination-active"
        />
      </div>
    </div>
  );
};

export default BloodGroups;
